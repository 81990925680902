import axios from "axios";
import { MENU_IDS, BLOCK_TITLES } from "config.js";

export function loadNodeFromServer(url, search) {
	return axios
		.get(`api/node?url=${encodeURIComponent(url)}${search ? "&search=" + encodeURIComponent(search) : ""}`)
		.then(result => {
			if (typeof result.data !== "object") {
				throw new Error("Response not an object");
			}
			return result.data;
		});
}

export function loadLanguagesFromServer() {
	return axios.get("api/languages").then(result => result.data);
}

export function loadTermsFromServer() {
	return axios.get("api/terms").then(result => result.data);
}

export function loadMenusFromServer() {
	return axios.get("api/menus", { params: { ids: MENU_IDS } }).then(result => result.data);
}

export function loadBlocksFromServer() {
	return axios.get("api/blocks", { params: { titles: BLOCK_TITLES } }).then(result => result.data);
}

export const submitForm = (formId, url, formData) =>
	axios.post(
		`api/form?formId=${encodeURIComponent(formId)}${url ? "&url=" + encodeURIComponent(url) : ""}`,
		formData,
		{
			timeout: 0 // Do not timeout because the user may be uploading files.
		}
	);

/**
 * Generic network error handling.
 */
export function handleError(t, error) {
	console.log(error);
	if (error.response && error.response.status === 401) {
		window.alert(t("api.unauthorized"));
		return;
	}
	if (error.code === "ECONNABORTED" || !window.navigator.onLine) {
		window.alert(t("api.network"));
		return;
	}
	window.alert(t("api.error"));
}

export function loadNextItemsFromServer(list) {
	const nextPage = list.page + 1;
	const idsToLoad = list.ids.slice(nextPage * list.itemsPerPage, nextPage * list.itemsPerPage + list.itemsPerPage);
	return axios
		.get("api/items", { params: { ids: idsToLoad, type: list.itemType, words: list.words } })
		.then(result => ({
			...list,
			items: list.items.concat(result.data),
			page: nextPage
		}));
}

export const requestNewsletterSubscriptionCreation = formData =>
	axios.post("api/newsletter-subscription/request/create", formData);

export const confirmNewsletterSubscriptionCreation = (id, hash) =>
	axios.get(`api/newsletter-subscription/confirm/create/${id}/${hash}`);

export const requestNewsletterSubscriptionDeletion = formData =>
	axios.post("api/newsletter-subscription/request/delete", formData);

export const confirmNewsletterSubscriptionDeletion = (id, hash) =>
	axios.get(`api/newsletter-subscription/confirm/delete/${id}/${hash}`);
