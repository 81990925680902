import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { ChildAbuseTeaser } from "nodes/ChildAbuse.js";
import MagicGrid from "react-magic-grid";

export default class ChildAbuseViewNodeList extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		list: PropTypes.object.isRequired
	};

	render() {
		const { t, list } = this.props;

		return (
			<div className="childAbuseMagicGrid">
				<MagicGrid static animate={false} gutter={0} as="ul">
					{list.items.map((node, index) => (
						<li key={`s-${index}`}>
							<ChildAbuseTeaser t={t} node={node} />
						</li>
					))}
				</MagicGrid>
			</div>
		);
	}
}
