import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { PageTeaser } from "nodes/Page.js";
import { EventTeaser } from "nodes/Event.js";
import { NewsTeaser } from "nodes/News.js";
import { GuideTeaser } from "nodes/Guide.js";
import { DocumentTeaser } from "nodes/Document.js";
import { StaffMemberTeaser } from "nodes/StaffMember.js";
import { FaqTeaser } from "nodes/Faq.js";
import { AgreementTeaser } from "nodes/Agreement.js";
import { OperationTeaser } from "nodes/Operation.js";
import { TenderTeaser } from "nodes/Tender.js";
import { EtenderTeaser } from "nodes/Etender.js";
import { MultimediaTeaser } from "nodes/Multimedia.js";
import { CrimeAreasTeaser } from "nodes/CrimeAreas.js";
import { InternshipTeaser } from "nodes/Internship.js";
import VacanciesViewNodeList from "nodes/listing/views/VacanciesViewNodeList.js";
import MultimediaViewNodeList from "nodes/listing/views/MultimediaViewNodeList.js";
import StaffMembersViewNodeList from "nodes/listing/views/StaffMembersViewNodeList.js";
import ChildAbuseViewNodeList from "nodes/listing/views/ChildAbuseViewNodeList.js";
import { loadNextItemsFromServer, handleError } from "skeleton/DataAccess.js";
import NodeListSearchFormFields from "nodes/listing/NodeListSearchFormFields.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { linkPropTypes } from "common/Weblinks.js";

export const listPropTypes = PropTypes.exact({
	id: PropTypes.string.isRequired, // TODO SOM one of listing ids
	title: PropTypes.string,
	titleLink: linkPropTypes,
	itemsPerPage: PropTypes.number,
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	page: PropTypes.number,
	filters: PropTypes.object,
	ids: PropTypes.arrayOf(PropTypes.number),
	totalItems: PropTypes.number,
	totalItemsLimit: PropTypes.number.isRequired,
	itemType: PropTypes.oneOf(["node", "term", "talentlink", "etendering"]).isRequired,
	info: PropTypes.string,
	footer: PropTypes.string
});

export default class NodeList extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		list: listPropTypes.isRequired,
		search: PropTypes.string
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.list !== this.props.list) {
			this.setState({ list: this.props.list });
		}
	}

	state = {
		list: this.props.list
	};

	hasMore = () =>
		!this.state.list.ids
			? false
			: this.state.list.page * this.state.list.itemsPerPage + this.state.list.itemsPerPage <
			  this.state.list.ids.length;

	loadMore = () => {
		const { t } = this.props;
		const { list } = this.state;
		this.setState({ list: { ...list, loadingStatus: "loading" } });
		loadNextItemsFromServer(list)
			.then(list => this.setState({ list: { ...list, loadingStatus: undefined } }))
			.catch(error =>
				handleError(
					t,
					error,
					undefined, // 401
					undefined, // 404
					() => this.setState({ list: { ...list, loadingStatus: "error-5xx" } }),
					() => this.setState({ list: { ...list, loadingStatus: "error-network" } })
				)
			);
	};

	render() {
		const { t, search } = this.props;
		const { list } = this.state;

		const renderList = list => {
			if (list.id === "multimedia") {
				return <MultimediaViewNodeList t={t} list={list} />;
			}
			if (list.id === "staff_members") {
				return <StaffMembersViewNodeList t={t} list={list} />;
			}
			if (list.id === "vacancies") {
				return <VacanciesViewNodeList t={t} list={list} />;
			}
			if (list.id === "child_abuse") {
				return <ChildAbuseViewNodeList t={t} list={list} />;
			}
			return (
				<Fragment>
					<ul className="layout">
						{list.items.map((node, index) => (
							<li key={index}>
								{node.type === "agreement" && <AgreementTeaser t={t} node={node} />}
								{node.type === "document" && <DocumentTeaser t={t} node={node} />}
								{node.type === "etendering" && <EtenderTeaser t={t} node={node} />}
								{node.type === "event" && <EventTeaser t={t} node={node} />}
								{node.type === "faq" && <FaqTeaser t={t} node={node} />}
								{node.type === "guide" && <GuideTeaser t={t} node={node} />}
								{node.type === "internship" && <InternshipTeaser t={t} node={node} />}
								{node.type === "multimedia" && <MultimediaTeaser t={t} node={node} />}
								{node.type === "news" && <NewsTeaser t={t} node={node} relatedContentView={false} />}
								{node.type === "operation" && <OperationTeaser t={t} node={node} />}
								{node.type === "page" && <PageTeaser t={t} node={node} />}
								{node.type === "staff_member" && <StaffMemberTeaser t={t} node={node} />}
								{node.type === "tender" && <TenderTeaser t={t} node={node} />}
								{node.type === "crime_areas" && <CrimeAreasTeaser t={t} node={node} />}
							</li>
						))}
					</ul>
					<button
						className="button"
						onClick={this.loadMore}
						disabled={list.loadingStatus === "loading"}
						style={this.hasMore() ? undefined : { display: "none" }}
						ref={node => (this.moreButtonDomNode = node)}
					>
						{t(list.loadingStatus === "loading" ? "Loading.loading" : "more")}
					</button>
				</Fragment>
			);
		};
		return (
			<Fragment>
				<div className={`NodeList layout curve ${list.id}`} key={list.id}>
					<div>
						{list.title && <h2>{list.title}</h2>}
						{list.info && <p className="info">{list.info}</p>}
						<NodeListSearchFormFields t={t} list={list} search={search} />
						{list.totalItems > 0 && (
							<p className="results">
								{t("results")}{" "}
								<span>
									{t(list.totalItems === list.totalItemsLimit ? "items_more" : "items", {
										items: list.totalItems
									})}
								</span>
							</p>
						)}
						{list.totalItems ? (
							<Fragment>{renderList(list)}</Fragment>
						) : (
							<div className="no-results">
								<p>{t("no_results")}</p>
							</div>
						)}
					</div>
				</div>
				<Fragment>{list.footer && <ClickableDrupalContent content={list.footer} />}</Fragment>
			</Fragment>
		);
	}
}
