import "focus-visible"; // ERA-157 https://github.com/WICG/focus-visible/issues/124
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Router, Route } from "react-router-dom";
import history from "skeleton/history.js";
import Loading from "common/Loading.js";
import {
	loadLanguagesFromServer,
	loadMenusFromServer,
	loadTermsFromServer,
	loadBlocksFromServer,
	handleError
} from "skeleton/DataAccess.js";
import axios from "axios";
import AppHeader from "skeleton/AppHeader.js";
import AppFooter from "skeleton/AppFooter.js";
import NodeLoader from "nodes/NodeLoader.js";
import Polyglot from "node-polyglot";
import i18n from "i18n.en.js";
const polyglot = new Polyglot({ locale: "en", phrases: i18n });
const t = polyglot.t.bind(polyglot);

export class RoutedApp extends PureComponent {
	render = () => (
		<Router history={history}>
			<Route
				path=":url(/.*)"
				render={props => (
					<App
						url={props.match.params.url}
						search={props.location.search ? props.location.search.replace(/^\?/, "") : undefined}
						t={t}
					/>
				)}
			/>
		</Router>
	);
}

export default class App extends PureComponent {
	state = {
		languages: undefined,
		terms: undefined
	};

	static propTypes = {
		url: PropTypes.string.isRequired,
		search: PropTypes.string,
		t: PropTypes.func.isRequired
	};

	componentDidMount() {
		// ssr.php may have already provided the data we need.
		if (window.SERVER_DATA && window.SERVER_DATA.App) {
			this.setState(window.SERVER_DATA.App);
			delete window.SERVER_DATA.App;
			return;
		}
		window.document.title = this.props.t("title");
		this.loadCoreDataFromServerAndSetState();
	}

	/**
	 * Loads core data from server such as languages, menus, terms.
	 * These usually never change during the front-end lifetime (unless
	 * an action mutates them).
	 *
	 */
	loadCoreDataFromServerAndSetState(assets = ["languages", "terms", "menus", "blocks"], onSuccess) {
		const { t } = this.props;

		// Keep track of a global incremental request id so we can ignore out of
		// date (stale) responses.
		const requestId = (this.lastRequestId = (this.lastRequestId || 0) + 1);
		axios
			.all([
				assets.includes("languages") &&
					loadLanguagesFromServer().then(languages => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ languages: languages });
					}),
				assets.includes("menus") &&
					loadMenusFromServer().then(menus => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ menus: menus });
					}),
				assets.includes("terms") &&
					loadTermsFromServer().then(terms => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ terms: terms });
					}),
				assets.includes("blocks") &&
					loadBlocksFromServer().then(blocks => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ blocks: blocks });
					})
			])
			.then(() => {
				if (onSuccess) onSuccess();
			})
			// One (or more) of the server requests failed.
			.catch(error => handleError(t, error));
	}

	/**
	 * Determines whether core state has been loaded from the server.
	 */
	isCoreStateLoaded = state =>
		state.languages !== undefined &&
		state.menus !== undefined &&
		state.terms !== undefined &&
		state.blocks !== undefined;

	render() {
		const { menus, blocks, languages } = this.state;
		const { url, search, t } = this.props;

		// Do not render application until core state has loaded.
		// We need this so we can ensure the body of the application will do have
		// those to work with.
		// This part of the process is only applicable while development, because
		// ssr.php already provides these on initial page view in window.SERVER_DATA.
		if (!this.isCoreStateLoaded(this.state)) {
			return <Loading t={t} />;
		}

		return (
			<div className="App">
				{/* Passing search={search} in AppHeader to make it re-render when search changes */}
				<AppHeader t={t} search={search} menu={menus["main"]} languages={languages} />
				<main role="main">
					{/* TODO: JC pull node loading into App */}
					<NodeLoader
						t={t}
						url={url}
						search={search}
						shouldLoadNode={true}
						blocks={blocks}
						menu={menus["main"]}
						languages={languages}
					/>
				</main>
				<AppFooter t={t} blocks={blocks} />
			</div>
		);
	}
}
