import { PureComponent } from "react";
import PropTypes from "prop-types";

export const getTitle = (title, separator = " | ") => {
	return typeof title === "string" ? title : Array.isArray(title) ? title.join(separator) : undefined;
};

export default class DocumentTitle extends PureComponent {
	static propTypes = {
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
		separator: PropTypes.string
	};

	// componentWillUnmount() {
	// 	// On unmount, restore to the original title.
	// 	if (this.originalTitle) window.document.title = this.originalTitle;
	// }

	render() {
		// Produce the desired title.
		const title = getTitle(this.props.title, this.props.separator);

		// Exit if title is empty.
		if (!title) return null;

		// If we haven't stored the original document title, store it.
		if (!this.originalTitle) this.originalTitle = window.document.title;

		// If the desired title is different that what is on the document, set it.
		if (window.document.title !== title) window.document.title = title;

		// Render no JSX.
		return null;
	}
}
