import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DocumentMeta from "common/DocumentMeta.js";
import Agreement from "nodes/Agreement.js";
import ChildAbuse from "nodes/ChildAbuse.js";
import Country from "nodes/Country.js";
import CrimeAreas from "nodes/CrimeAreas.js";
import Document from "nodes/Document.js";
import Event from "nodes/Event.js";
import Faq from "nodes/Faq.js";
import Guide from "nodes/Guide.js";
import HomePage from "nodes/HomePage.js";
import Internship from "nodes/Internship.js";
import Multimedia from "nodes/Multimedia.js";
import News from "nodes/News.js";
import Operation from "nodes/Operation.js";
import Page from "nodes/Page.js";
import Search from "nodes/Search.js";
import Sitemap from "nodes/Sitemap.js";
import StaffMember from "nodes/StaffMember.js";
import Tender from "nodes/Tender.js";
import Timeline from "nodes/Timeline.js";
import Vacancy from "nodes/Vacancy.js";
import DocumentTitle from "common/DocumentTitle.js";
import { nodePropTypes } from "nodes/PropTypes.js";
import NodeHeader from "common/NodeHeader.js";
import { getBreadcrumbs, menuPropTypes } from "common/Menu.js";
import { languagesPropTypes } from "common/Languages.js";

const shouldRenderHeader = node => node.type !== "home_page" && node.type !== "timeline";

export default class NodePanel extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		open: PropTypes.bool.isRequired,
		node: nodePropTypes,
		blocks: PropTypes.object,
		search: PropTypes.string,
		menu: menuPropTypes.isRequired,
		languages: languagesPropTypes.isRequired
	};

	render() {
		const { t, open, node, blocks, search, menu, languages } = this.props;

		let content = null;
		let extraCssClass = "NodePanel";
		if (open && node) {
			extraCssClass += ` ct_${node.type}`;
			// Use key={node.id} to prevent changing node content of same type within the same component.
			// This prevents displaying a stale HTML img within the new node.
			content = (
				<div className={extraCssClass} key={node.id} lang={node.language}>
					<DocumentTitle title={[node.title, t("title")]} />
					<DocumentMeta description={node.summary} />
					{shouldRenderHeader(node) && (
						<NodeHeader t={t} node={node} breadcrumb={getBreadcrumbs(menu, node.alias)} />
					)}
					{node.type === "agreement" && <Agreement t={t} node={node} />}
					{node.type === "child_abuse" && <ChildAbuse t={t} node={node} blocks={blocks} />}
					{node.type === "country" && <Country t={t} node={node} />}
					{node.type === "document" && <Document t={t} node={node} />}
					{node.type === "event" && <Event t={t} node={node} />}
					{node.type === "faq" && <Faq t={t} node={node} />}
					{node.type === "guide" && <Guide t={t} node={node} />}
					{node.type === "home_page" && <HomePage t={t} node={node} languages={languages} />}
					{node.type === "multimedia" && <Multimedia t={t} node={node} />}
					{node.type === "news" && <News t={t} node={node} blocks={blocks} />}
					{node.type === "operation" && <Operation t={t} node={node} />}
					{node.type === "page" && <Page t={t} node={node} blocks={blocks} search={search} />}
					{node.type === "search" && <Search t={t} node={node} search={search} />}
					{node.type === "sitemap" && <Sitemap t={t} node={node} menu={menu} />}
					{node.type === "staff_member" && <StaffMember t={t} node={node} />}
					{node.type === "tender" && <Tender t={t} node={node} />}
					{node.type === "timeline" && <Timeline t={t} node={node} />}
					{/* The following are not actual nodes */}
					{node.type === "crime_areas" && <CrimeAreas t={t} node={node} />}
					{node.type === "internship" && <Internship t={t} node={node} />}
					{node.type === "vacancy" && <Vacancy t={t} node={node} />}
				</div>
			);
		}
		return content;
	}
}
