import React, { Fragment } from "react";
import { BACKEND_PATH, DEFAULT_LANGUAGE } from "config.js";

/**
 * Returns a human readable representation of the specified bytes file size.
 * Only supports byte, KB, MB and GB units since this is a webapp. To be 100%
 * correct we'd need to output byte, KiB, MiB and GiB, but the average web user
 * is not yet familiar with those units.
 */
export function getHumanReadableFileSize(bytes) {
	if (typeof bytes !== "number" || isNaN(bytes)) return "";
	if (bytes < 1024) return `${bytes} bytes`;
	if (bytes < 1024 * 1024) return `${parseFloat((bytes / 1024).toFixed(1))} KB`;
	if (bytes < 1024 * 1024 * 1024) return `${parseFloat((bytes / 1024 / 1024).toFixed(1))} MB`;
	return `${parseFloat((bytes / 1024 / 1024 / 1024).toFixed(1))} GB`;
}

/**
 * Ease-in-out parametric as shown in https://stackoverflow.com/a/25730573/72478
 * For specified value between 0 and 1 the result will be between 0 and 1.
 */
export const easeInOutParametric = value => {
	const squareValue = value * value;
	return squareValue / (2 * (squareValue - value) + 1);
};

/**
 * Will toggle animate the height of an element.
 * Animating an element's height from 0 to auto is not fully possible via CSS.
 *
 * Demo: https://codesandbox.io/s/css-height-auto-animation-8jgbz
 */
export const toggleHeight = (element, duration = 300, easing = easeInOutParametric) => {
	const now = Date.now();

	// An existing animation is in place.
	// Change params and let it finish the job.
	if (element.dataset.start) {
		const start = Number(element.dataset.start);
		const end = Number(element.dataset.end);
		const direction = Number(element.dataset.direction);
		element.dataset.end = now - start + now;
		element.dataset.start = now - (end - now);
		element.dataset.direction = -direction;
		return;
	}

	// Fresh animation.
	element.dataset.end = now + duration;
	element.dataset.start = now;
	element.dataset.direction = element.clientHeight === 0 ? +1 : -1;
	const calculateAndApplyNextFrame = () => {
		const now = Date.now();
		const start = Number(element.dataset.start);
		const end = Number(element.dataset.end);
		const direction = Number(element.dataset.direction);
		// Animation is done.
		if (now > end) {
			if (direction === 1) {
				expandToFinalHeight(element);
			} else {
				collapseToFinalHeight(element);
			}
			delete element.dataset.end;
			delete element.dataset.start;
			delete element.dataset.direction;
			return;
		}
		const frame =
			direction === 1 ? easing((now - start) / (end - start)) : 1 - easing((now - start) / (end - start));
		element.style.display = "block";
		element.style.height = element.scrollHeight * frame + "px";
		element.style.overflow = "hidden";
		window.requestAnimationFrame(calculateAndApplyNextFrame);
	};
	window.requestAnimationFrame(calculateAndApplyNextFrame);
};

/**
 * See #toggleHeight().
 */
const expandToFinalHeight = element => {
	element.style.display = "block";
	element.style.height = "auto";
	element.style.overflow = "visible";
};

/**
 * See #toggleHeight().
 */
const collapseToFinalHeight = element => {
	element.style.display = "none"; // Display none so links/buttons within are not focusable via tab.
	element.style.height = "0px";
	element.style.overflow = "hidden";
};

/**
 * Returns the date and time of the specified timestamp.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 * @param {string} locale - The locale (in IETF BCP 47 language tag format).
 * @param {Object} config - An object that contains the DateTimeFormatOptions.
 */
export const renderDate = (
	timestamp,
	locale,
	config = {
		day: "2-digit",
		month: "short",
		year: "numeric"
	}
) => new Date(timestamp * 1000).toLocaleString(locale, config);
/**
 * Returns the date of the specified timestamp in ISO 8601 format.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 */
export const renderDateIso = (timestamp, length = 10) => new Date(timestamp * 1000).toISOString().substr(0, length);

/**
 * Returns the URL of a node using the specified alias and language.
 */
export function renderUrl(alias, language) {
	if (!language || language === DEFAULT_LANGUAGE) return alias;
	return `${alias}:${language}`;
}

/**
 * Returns the URL parameters representation of an HTML form.
 * e.g:
 * - foo=bar
 * - foo=bar&example=123
 */
export const serializeForm = (formDomNode, trim = true) => {
	const result = [];
	formDomNode.querySelectorAll("[name]").forEach(field => {
		if (field.type === "checkbox" && !field.checked) return;
		if (field.type === "radio" && !field.checked) return;
		const value = trim ? field.value.trim() : field.value;
		if (!value) return; // Continue.
		result.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(value));
	});
	return result.join("&");
};

/**
 * Sets the field values of a form based on a URL parameters string.
 */
export const unserializeForm = (formDomNode, search) => {
	const urlParams = new URLSearchParams(search);
	formDomNode.querySelectorAll("[name]").forEach(field => {
		if (urlParams.get(field.name) === null) return;
		switch (field.type) {
			case "radio":
				field.checked = urlParams.get(field.name) === field.value;
				break;
			case "checkbox":
				field.checked = urlParams.getAll(field.name).includes(field.value);
				break;
			default:
				field.value = urlParams.get(field.name);
				break;
		}
	});
};

/**
 * Returns the FormData object for an HTML form.
 */
export const serializeFormToFormData = (formDomNode, trim = true) => {
	const formData = new FormData();
	formDomNode.querySelectorAll("[name]").forEach(field => {
		if (field.type === "checkbox" && !field.checked) return; // Continue.
		if (field.type === "radio" && !field.checked) return; // Continue.
		if (field.type === "file" && field.files[0]) {
			formData.append(field.name, field.files[0], field.files[0].name);
			return; // Continue.
		}
		const value = trim ? field.value.trim() : field.value;
		if (!value) return; // Continue.
		formData.append(field.name, value);
	});
	return formData;
};

/**
 * Decodes query parameters from the specified URL query string (for the form
 * "foo=bar&test=123").
 *
 */
export const decodeQuery = query => {
	if (typeof query !== "string") return {};
	return query.split("&").reduce((result, param) => {
		const tokens = param.split("=");
		if (tokens.length !== 2) return result; // continue
		try {
			result[decodeURIComponent(tokens[0]).trim()] = decodeURIComponent(tokens[1] || "").trim();
		} catch (error) {
			// Ignore URIError and skip parameter.
		}
		return result;
	}, {});
};

/**
 * Encodes a query parameter object into a URL query string (into the form
 * "foo=bar&test=123").)
 */
export const encodeQuery = params =>
	Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&");

/**
 * Escapes HTML.
 *
 * https://stackoverflow.com/a/6234804/72478
 */
export const escapeHtml = html =>
	html
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");

export function isExternalLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^(?:[a-z+]+:)?\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+/) !== null;
}

// https://stackoverflow.com/a/3561711/72478
const escapeForRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
const cmsLinkRegExp = new RegExp("^" + escapeForRegExp(BACKEND_PATH));
export function isCmsLink(href) {
	if (!href || typeof href !== "string") return false;
	return cmsLinkRegExp.test(href);
}

const staticAssetLinkRegExp = new RegExp(/^[^?]+\.[^/?]{2,5}(\?.*)?$/);
export function isStaticAssetLink(href) {
	// ^/.+\.[^/]{2,5}$
	if (!href || typeof href !== "string") return false;
	return staticAssetLinkRegExp.test(href);
}

export function isAnchorLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^#/) !== null;
}

export function isMailLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^mailto:/) !== null;
}

export function isTelLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^tel:/) !== null;
}

// https://github.com/facebook/react/issues/9125#issuecomment-285531461
const createLatLonPropTypes = isRequired => (props, propName, componentName) => {
	if (!isRequired && props[propName] === undefined) return;
	if (
		!Array.isArray(props[propName]) ||
		props[propName].length !== 2 ||
		!props[propName].every(value => typeof value === "number")
	) {
		return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`);
	}
};

export const latLonPropTypes = createLatLonPropTypes(false);
latLonPropTypes.isRequired = createLatLonPropTypes(true);

export const getClickedAnchor = target => {
	if (!target || !target.tagName) return undefined;
	if (target.tagName === "BODY") return undefined;
	if (target.tagName === "A") return target;
	return getClickedAnchor(target.parentElement);
};

/**
 * Converts the specified text to a react Fragment with all newlines replaces with <br/>.
 */
export const renderFragmentWithNewlines = text =>
	text.split("\n").map((text, index) => (
		<Fragment key={index}>
			{index > 0 && <br />}
			{text}
		</Fragment>
	));

/**
 * A wrapper around window.scrollTo to support old browsers.
 */
export const scrollTo = options => {
	try {
		window.scrollTo(options);
	} catch (error) {
		window.scrollTo(options.left || 0, options.top || 0);
	}
};
