import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { renderMenuTree, menuPropTypes } from "common/Menu.js";

export const sitemapPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["sitemap"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	summary: PropTypes.string,
	body: PropTypes.string
});

export default class Sitemap extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: sitemapPropTypes,
		menu: menuPropTypes.isRequired
	};

	render() {
		const { node, menu } = this.props;
		return (
			<Fragment>
				{node.body && <ClickableDrupalContent content={node.body} />}
				<nav role="navigation" className="sitemap">
					{renderMenuTree(menu)}
				</nav>
			</Fragment>
		);
	}
}
