import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { renderUrl } from "common/utils.js";
import SocialShare from "common/SocialShare.js";
import NodeHeaderMeta from "common/NodeHeaderMeta.js";
import NodeHeaderBottomMeta from "common/NodeHeaderBottomMeta.js";

export const openShareDropdown = e => {
	const dropdown = e.target.parentElement.querySelector("div");
	if (dropdown) dropdown.classList.toggle("open");
};

export default class NodeHeader extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired, // Should have used nodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
		breadcrumb: PropTypes.array // TODO: SOM breadcrumb component and props
	};

	render() {
		const { t, node, breadcrumb } = this.props;
		let extraCssClass = "NodeHeader layout dark-blue";
		if (node.cssClass) extraCssClass += ` ${node.cssClass}`;
		return (
			<header className={extraCssClass}>
				<div>
					<div className="header-top">
						{breadcrumb.length > 0 && (
							<ul className="breadcrumb">
								<li key="home">
									<Link to="/">{t("home")}</Link>
								</li>
								{breadcrumb.map((item, index) => (
									<Fragment key={index}>
										{index < breadcrumb.length - 1 && (
											<li key={index}>
												<Link to={item.url}>{item.name}</Link>
											</li>
										)}
									</Fragment>
								))}
							</ul>
						)}
						<SocialShare t={t} url={renderUrl(node.alias, node.language)} />
					</div>
					<h1>{node.title}</h1>
					<NodeHeaderMeta t={t} node={node} />
					<NodeHeaderBottomMeta t={t} node={node} />
					{node.navigation && (
						<nav role="navigation" aria-label="Next or previous item" className="node-navigation">
							{node.navigation.previous && <Link to={node.navigation.previous} className="previous"> {t("previous")}</Link>}
							{node.navigation.next && <Link to={node.navigation.next} className="next"> {t("next")}</Link>}
						</nav>
					)}
				</div>
			</header>
		);
	}
}
