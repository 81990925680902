import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isExternalLink } from "common/utils.js";

export const linkPropTypes = PropTypes.exact({
	url: PropTypes.string.isRequired,
	title: PropTypes.string
});

export const renderLink = (link, className, title) => {
	if (!link) return null;
	return isExternalLink(link.url) ? (
		<a href={link.url} className={className} rel="external noopener noreferrer">
			{title || link.title || link.url}
		</a>
	) : (
		<Link to={link.url} className={className}>
			{title || link.title || link.url}
		</Link>
	);
};

export const weblinksPropTypes = PropTypes.arrayOf(linkPropTypes);

export default class Weblinks extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		weblinks: weblinksPropTypes
	};
	render() {
		const { t, weblinks } = this.props;
		if (!weblinks || !weblinks.length) return null;
		return (
			<div className="Weblinks">
				<h2>{t("Weblinks.label")}</h2>
				<ul>
					{weblinks.map((link, index) => (
						<li key={index}>{renderLink(link)}</li>
					))}
				</ul>
			</div>
		);
	}
}
