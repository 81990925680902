import React, { PureComponent } from "react";
import PropTypes from "prop-types";

// https://newbedev.com/setting-iframe-height-to-scrollheight-in-reactjs
export default class IframeWrapper extends PureComponent {
	static propTypes = {
		title: PropTypes.string,
		src: PropTypes.string,
		srcDoc: PropTypes.string
	};

	state = { contentHeight: 500 };

	handleResize = () => {
		const { body, documentElement } = this.container.contentWindow.document;
		const contentHeight = Math.max(
			body.clientHeight,
			body.offsetHeight,
			body.scrollHeight,
			documentElement.clientHeight,
			documentElement.offsetHeight,
			documentElement.scrollHeight
		);
		if (contentHeight !== this.state.contentHeight) this.setState({ contentHeight });
	};

	onLoad = () => {
		this.container.contentWindow.addEventListener("resize", this.handleResize);
		this.handleResize();
	};

	componentWillUnmount() {
		this.container.contentWindow.removeEventListener("resize", this.handleResize);
	}

	render() {
		const { title, src, srcDoc } = this.props;
		const { contentHeight } = this.state;
		return (
			<div className="iframeWrapper">
				<iframe
					ref={container => {
						this.container = container;
					}}
					title={title}
					onLoad={this.onLoad}
					style={{ width: "100%", height: `${contentHeight}px` }}
					src={src}
					srcDoc={srcDoc}
					scrolling="no"
				></iframe>
			</div>
		);
	}
}
