import React, { PureComponent, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import ImageGallery from "common/ImageGallery.js";
import RelatedContent from "common/RelatedContent.js";
import Image, { imagePropTypes } from "common/Image.js";

export const multimediaPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["multimedia"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	body: PropTypes.string,
	summary: PropTypes.string,
	mainImage: imagePropTypes,
	images: PropTypes.arrayOf(imagePropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	subtitle: PropTypes.string,
	multimediaCategory: termPropTypes,
	relatedContent: PropTypes.array, // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	ogImage: PropTypes.string,
	ogDescription: PropTypes.string
});

export const multimediaTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["multimedia"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	mainImage: imagePropTypes,
	multimediaCategory: termPropTypes
});

export default class Multimedia extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: multimediaPropTypes
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<ImageGallery t={t} images={node.images} type="multimedia" />
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Meta t={t} node={node} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class MultimediaTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: multimediaTeaserPropTypes,
		relatedContentView: PropTypes.bool
	};

	static defaultProps = {
		relatedContentView: false // Set different image size in case that appears on related content list EPOLD8-291.
	};

	render() {
		const { node, relatedContentView } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && (
						<Image image={node.mainImage} type={relatedContentView ? "related_content" : "teaser"} />
					)}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
				</div>
			</article>
		);
	}
}
