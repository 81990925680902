import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Menu, { menuPropTypes } from "common/Menu.js";
import { languagesPropTypes } from "common/Languages.js";
import LanguageSwitcher from "common/LanguageSwitcher.js";

export default class AppHeader extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		menu: menuPropTypes.isRequired,
		languages: languagesPropTypes.isRequired
	};

	state = {
		activeMenu: false
	};

	toggleMenu = () => {
		this.setState({ activeMenu: !this.state.activeMenu });
	};

	componentDidMount() {
		document.addEventListener("click", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick, false);
	}

	// Close menu on outside or link(menu item) click
	handleClick = e => {
		if (
			this.menuToggleDomNode.classList.contains("active") &&
			((!this.menuToggleDomNode.contains(e.target) && !this.menuDomNode.contains(e.target)) ||
				e.target.tagName.toLowerCase() === "a")
		) {
			this.toggleMenu();
		}
	};

	render() {
		const { t, menu, languages } = this.props;
		return (
			<header className="AppHeader">
				<div className="logo">
					<Link to="/">{t("title")}</Link>
				</div>
				<div
					ref={node => (this.menuDomNode = node)}
					className={`mainNavigation ${this.state.activeMenu ? "open" : ""}`}
				>
					<Menu t={t} menu={menu} />
				</div>
				<ul>
					<li className="menu">
						<button
							className={this.state.activeMenu ? "active" : ""}
							onClick={this.toggleMenu}
							ref={node => (this.menuToggleDomNode = node)}
						>
							<span>{t("AppHeader.menu")}</span>
						</button>
					</li>
					<li className="search">
						<Link to="/search">{t("AppHeader.search")}</Link>
					</li>
					<li className="contact">
						<Link to="/contact-us">{t("AppHeader.contact")}</Link>
					</li>
					<li className="language">
						<LanguageSwitcher t={t} languages={languages} />
					</li>
				</ul>
			</header>
		);
	}
}
