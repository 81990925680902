import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class NodeHeaderBottomMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired
	};
	render() {
		const { t, node } = this.props;
		if (!node.files && !node.isEmpact && !node.applicationUrl) return null;
		return (
			<div className="header-bottom">
				<ul>
					{node.files && (
						<li>
							<a className="button small turquoise anchor" href="#downloads">
								{t("Downloads.label")}
							</a>
						</li>
					)}
					{node.isEmpact && (
						<li>
							<a className="button small turquoise anchor" href="#empact">
								{t("Empact.label")}
							</a>
						</li>
					)}
					{node.applicationUrl && (
						<li>
							<a className="button small turquoise more" href={node.applicationUrl}>
								{t("apply")}
							</a>
						</li>
					)}
				</ul>
			</div>
		);
	}
}
