import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { renderUrl } from "common/utils.js";

export const shouldRender = languages => languages && languages.length > 1;

export const languagesPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		code: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired
	})
);

export default class Languages extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		languages: PropTypes.arrayOf(PropTypes.string).isRequired,
		language: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired
	};

	render() {
		const { t, languages, language, alias } = this.props;
		if (!shouldRender(languages)) return null;
		return (
			<nav role="navigation" aria-label={t("Languages.aria")} className="Languages">
				<h2>{t("Languages.heading")}</h2>
				<ul>
					{languages.map((lang, index) => (
						<li key={index}>
							<NavLink className="button small turquoise" isActive={() => lang === language} to={renderUrl(alias, lang)}>
								{lang}
							</NavLink>
						</li>
					))}
				</ul>
			</nav>
		);
	}
}
