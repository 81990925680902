import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { renderFragmentWithNewlines, serializeFormToFormData } from "common/utils.js";
import { termPropTypes } from "common/Term.js";
import {
	requestNewsletterSubscriptionCreation,
	confirmNewsletterSubscriptionCreation,
	requestNewsletterSubscriptionDeletion,
	confirmNewsletterSubscriptionDeletion
} from "skeleton/DataAccess.js";

// EPOLD8-212
export const renderNewsletterSubscriptionManagement = (t, node) => {
	if (!node.extraFunctionality) return null;
	const subscribeFunctionality = node.extraFunctionality.includes("NEWSLETTER_SUBSCRIPTION_MANAGEMENT");
	const deleteFunctionality = node.extraFunctionality.includes("NEWSLETTER_SUBSCRIPTION_MANAGEMENT_REMOVE");
	if (!subscribeFunctionality && !deleteFunctionality) return null;
	return (
		<Fragment>
			{subscribeFunctionality && <NewsletterSubscriptionCreation t={t} groups={node.newsletterCategoryGroups} />}
			{deleteFunctionality && <NewsletterSubscriptionDeletion t={t} />}
		</Fragment>
	);
};

// Match a URL hash such as:
// #123/PNOtuRbbCcn-xefOdFnaFxWdMdW1LVeABqx9FsPMLdI
const matchIdAndHashFromUrl = () => window.location.hash.match(/^#(?<id>[1-9][0-9]*)\/(?<hash>.+)$/);

// Remove hash from URL.
const resetIdAndHashFromUrl = () => window.history.replaceState(null, null, " ");

export const newsletterCategoryGroups = PropTypes.arrayOf(
	PropTypes.exact({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string,
		categories: PropTypes.arrayOf(termPropTypes).isRequired
	})
);

export class NewsletterSubscriptionCreation extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		groups: newsletterCategoryGroups
	};

	state = {
		requestStatus: undefined, // ['pending', 'success', 'error']
		confirmStatus: undefined // ['pending', 'success', 'error']
	};

	// Stores HTML form DOM element so we can submit and manipulate (bypassing react).
	newsletterSubscriptionForm = undefined;

	submitForm = e => {
		e.preventDefault();
		this.setState({ requestStatus: "pending" });
		requestNewsletterSubscriptionCreation(serializeFormToFormData(this.newsletterSubscriptionForm))
			.then(() => this.setState({ requestStatus: "success" }))
			.catch(() => this.setState({ requestStatus: "error" }));
	};

	componentDidMount() {
		const match = matchIdAndHashFromUrl();
		if (match) {
			resetIdAndHashFromUrl();
			this.setState({ confirmStatus: "pending" });
			confirmNewsletterSubscriptionCreation(match.groups.id, match.groups.hash)
				.then(() => this.setState({ confirmStatus: "success" }))
				.catch(() => this.setState({ confirmStatus: "error" }));
		}
	}

	// https://stackoverflow.com/a/60108369/72478
	categoryCheckboxChanged = () => {
		if (this.newsletterSubscriptionForm.querySelectorAll(":checked").length === 0) {
			this.newsletterSubscriptionForm
				.querySelectorAll("input.category")
				.forEach(element => element.setAttribute("required", "required"));
		} else {
			this.newsletterSubscriptionForm
				.querySelectorAll("input.category")
				.forEach(element => element.removeAttribute("required"));
		}
	};

	render() {
		const { t, groups } = this.props;
		const { requestStatus, confirmStatus } = this.state;

		if (confirmStatus)
			return (
				<div className="NewsletterSubscriptionManagement layout grey">
					{confirmStatus === "pending" && (
						<div className="message pending">
							<p>{t("Form.submissionStatus.pending")}</p>
						</div>
					)}
					{confirmStatus === "success" && (
						<div className="message success">
							<p>{t("Page.newsletterSubscriptionManagement.confirmSubscribe.success")}</p>
						</div>
					)}
					{confirmStatus === "error" && (
						<div className="message error">
							<p>{t("Page.newsletterSubscriptionManagement.confirmSubscribe.error")}</p>
						</div>
					)}
				</div>
			);

		return (
			<div className="NewsletterSubscriptionManagement layout grey">
				<div>
					{requestStatus === "success" && (
						<div className="message success">
							<p>{t("Page.newsletterSubscriptionManagement.requestSubscribe.success")}</p>
						</div>
					)}

					{requestStatus !== "success" && (
						<form
							className="Form"
							ref={form => (this.newsletterSubscriptionForm = form)}
							onSubmit={this.submitForm}
						>
							<input type="hidden" name="path" value={window.location.pathname} />
							{groups.map(newsletterCategoryGroup => (
								<div className="group" key={newsletterCategoryGroup.id}>
									<fieldset>
										<legend>{newsletterCategoryGroup.title}</legend>
										{newsletterCategoryGroup.description && (
											<ClickableDrupalContent content={newsletterCategoryGroup.description} />
										)}
										{newsletterCategoryGroup.categories.map(newsletterCategory => (
											<div className="field-type-checkbox" key={newsletterCategory.id}>
												<input
													type="checkbox"
													className="category"
													name="category[]"
													id={`category-${newsletterCategory.id}`}
													value={newsletterCategory.id}
													required
													onChange={this.categoryCheckboxChanged}
												/>
												<label htmlFor={`category-${newsletterCategory.id}`}>
													{newsletterCategory.title}
												</label>
											</div>
										))}
									</fieldset>
								</div>
							))}
							<label>
								<span>
									{t("Page.newsletterSubscriptionManagement.email")}
									<span className="required" aria-hidden="true">
										*
									</span>
								</span>
								<input type="email" name="email" required />
							</label>
							<fieldset>
								<legend>
									{t("Page.newsletterSubscriptionManagement.disclaimer.title")}
									<span className="required" aria-hidden="true">
										*
									</span>
								</legend>
								<p>
									{renderFragmentWithNewlines(
										t("Page.newsletterSubscriptionManagement.disclaimer.body")
									)}
								</p>
								<div className="field-type-checkbox">
									<input type="checkbox" required id="terms-and-conditions" />
									<label htmlFor="terms-and-conditions">
										{t("Page.newsletterSubscriptionManagement.disclaimer.accept")}
										<span className="required" aria-hidden="true">
											*
										</span>
									</label>
								</div>
							</fieldset>
							{requestStatus === "pending" && (
								<div className="message pending">
									<p>{t("Form.submissionStatus.pending")}</p>
								</div>
							)}
							{requestStatus === "error" && (
								<div className="message error">
									<p>{t("Form.submissionStatus.error")}</p>
								</div>
							)}
							<div className="actions">
								<button className="button" type="submit" disabled={requestStatus === "pending"}>
									{t("Page.newsletterSubscriptionManagement.requestSubscribe.button")}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		);
	}
}

export class NewsletterSubscriptionDeletion extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	state = {
		requestStatus: undefined, // ['pending', 'success', 'error', 'error404']
		confirmStatus: undefined // ['pending', 'success', 'error']
	};

	// Stores HTML form DOM element so we can submit and manipulate (bypassing react).
	newsletterSubscriptionForm = undefined;

	submitForm = e => {
		e.preventDefault();
		this.setState({ requestStatus: "pending" });
		requestNewsletterSubscriptionDeletion(serializeFormToFormData(this.newsletterSubscriptionForm))
			.then(() => this.setState({ requestStatus: "success" }))
			.catch(error => {
				if (error.response && error.response.status === 404) {
					this.setState({ requestStatus: "error404" });
				} else {
					this.setState({ requestStatus: "error" });
				}
			});
	};

	componentDidMount() {
		const match = matchIdAndHashFromUrl();
		if (match) {
			resetIdAndHashFromUrl();
			this.setState({ confirmStatus: "pending" });
			confirmNewsletterSubscriptionDeletion(match.groups.id, match.groups.hash)
				.then(() => this.setState({ confirmStatus: "success" }))
				.catch(() => this.setState({ confirmStatus: "error" }));
		}
	}

	render() {
		const { t } = this.props;
		const { requestStatus, confirmStatus } = this.state;

		if (confirmStatus)
			return (
				<div className="NewsletterSubscriptionManagement layout grey">
					{confirmStatus === "pending" && (
						<div className="message pending">
							<p>{t("Form.submissionStatus.pending")}</p>
						</div>
					)}
					{confirmStatus === "success" && (
						<div className="message success">
							<p>{t("Page.newsletterSubscriptionManagement.confirmUnsubscribe.success")}</p>
						</div>
					)}
					{confirmStatus === "error" && (
						<div className="message error">
							<p>{t("Page.newsletterSubscriptionManagement.confirmUnsubscribe.error")}</p>
						</div>
					)}
				</div>
			);

		return (
			<div className="NewsletterSubscriptionManagement layout grey">
				<div>
					{requestStatus === "success" && (
						<div className="message success">
							<p>{t("Page.newsletterSubscriptionManagement.requestUnsubscribe.success")}</p>
						</div>
					)}

					{requestStatus !== "success" && (
						<form
							className="Form"
							ref={form => (this.newsletterSubscriptionForm = form)}
							onSubmit={this.submitForm}
						>
							<input type="hidden" name="path" value={window.location.pathname} />
							<label>
								<span>
									{t("Page.newsletterSubscriptionManagement.email")}
									<span className="required" aria-hidden="true">
										*
									</span>
								</span>
								<input type="email" name="email" required />
							</label>
							{requestStatus === "pending" && (
								<div className="message pending">
									<p>{t("Form.submissionStatus.pending")}</p>
								</div>
							)}
							{requestStatus === "error" && (
								<div className="message error">
									<p>{t("Form.submissionStatus.error")}</p>
								</div>
							)}
							{requestStatus === "error404" && (
								<div className="message error">
									<p>{t("Page.newsletterSubscriptionManagement.requestUnsubscribe.error404")}</p>
								</div>
							)}
							<div className="actions">
								<button className="button" type="submit" disabled={requestStatus === "pending"}>
									{t("Page.newsletterSubscriptionManagement.requestUnsubscribe.button")}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		);
	}
}
