import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import history from "skeleton/history.js";
import {
	isExternalLink,
	isCmsLink,
	isStaticAssetLink,
	isAnchorLink,
	isMailLink,
	isTelLink,
	toggleHeight,
	getClickedAnchor
} from "common/utils.js";

/**
 * This component renders rich text (HTML) which comes directly from Drupal.
 * This is obviously unsafe (hence the usage of dangerouslySetInnerHTML), bypasses
 * react and depends on the Drupal content managers doing a good job.
 *
 * We need to be doing some JS enhancements/operations on that rich text, and since
 * we do not parse the contents of that rich text in order to "translate" it to
 * react components, we do all of them using JS on top of the already rendered HTML.
 * We currently:
 * - Handle clicks to external or internal content.
 */
export default class ClickableDrupalContent extends PureComponent {
	static propTypes = {
		content: PropTypes.string.isRequired,
		className: PropTypes.string
	};

	handleClick = e => {
		const anchor = getClickedAnchor(e.target);
		// Only handle clicks on "a" tags.
		if (!anchor) return;

		// Handle various types of links.
		const href = anchor.getAttribute("href");

		// These should be handled by browser.
		if (isExternalLink(href)) return;
		if (isCmsLink(href)) return;
		if (isStaticAssetLink(href)) return;
		if (isAnchorLink(href)) return;
		if (isMailLink(href)) return;
		if (isTelLink(href)) return;

		// Link to react which should be handled by react.
		e.preventDefault();
		history.push(href);
	};

	componentDidMount() {
		this.enhanceExpandablesContent();
		this.handleExternalLinksRel();
	}

	componentDidUpdate() {
		this.enhanceExpandablesContent();
		this.handleExternalLinksRel();
	}

	/**
	 * Mutates the rendered HTML content and set to anchors with rel="external"
	 * or class="external" specific rel attributes such as noopener and noreferrer.
	 */
	handleExternalLinksRel = () => {
		this.domNode.querySelectorAll('a[rel="external"]:not([target]),.external:not([target])').forEach(link => {
			link.setAttribute("rel", "external noopener noreferrer");
		});
	};

	/**
	 * Mutates the rendered HTML content and enhances all expandables, adding
	 * appropriate classes and aria attributes.
	 */
	enhanceExpandablesContent = () => {
		this.domNode.querySelectorAll(".expandable").forEach(expandable => {
			const button = expandable.querySelector("button");
			const content = expandable.querySelector(".expandable-content");
			if (!button || !content) return;
			const isExpanded = expandable.classList.contains("expanded");
			content.setAttribute("role", "region");
			button.setAttribute("aria-expanded", isExpanded);
			button.addEventListener(
				"click",
				() => {
					toggleHeight(content, 300);
					const expanded = expandable.classList.toggle("expanded");
					button.setAttribute("aria-expanded", expanded);
				},
				false
			);
		});
	};

	render() {
		const { content, className } = this.props;
		return (
			<div
				className={"ClickableDrupalContent" + (className ? " " + className : "")}
				onClick={this.handleClick}
				ref={node => (this.domNode = node)}
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		);
	}
}
