import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { renderDate, renderDateIso } from "common/utils.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { DEFAULT_TIMEZONE } from "config.js";

export const internshipPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["internship"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	body: PropTypes.string,
	applicationUrl: PropTypes.string.isRequired,
	restricted: PropTypes.bool.isRequired,
	restrictedAnnex: PropTypes.bool.isRequired,
	internship: PropTypes.bool.isRequired,
	deadline: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	published: PropTypes.number.isRequired,
	postType: PropTypes.string.isRequired,
	reportTo: PropTypes.string,
	contractTypeGroup: PropTypes.string,
	contractType: PropTypes.string.isRequired,
	department: PropTypes.string,
	referenceNumber: PropTypes.string
});

export const internshipTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["internship"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	restricted: PropTypes.bool.isRequired,
	deadline: PropTypes.number.isRequired,
	postType: PropTypes.string.isRequired,
	contractTypeGroup: PropTypes.string.isRequired,
	contractType: PropTypes.string.isRequired,
	department: PropTypes.string,
	referenceNumber: PropTypes.string
});

export default class Internship extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: internshipPropTypes
	};

	render() {
		const { node } = this.props;
		return <Fragment>{node.body && <ClickableDrupalContent content={node.body} />}</Fragment>;
	}
}

export class InternshipTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: internshipTeaserPropTypes
	};

	render() {
		const { t, node } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					<div className="post-type">
						<label className="show-for-sr">{t("Meta.label.postType")}</label>
						<span>{node.postType}</span>
					</div>
					<div className="deadline">
						<label>{t("Meta.label.deadline")}</label>
						<time date={renderDateIso(node.deadline, 24)}>
							{renderDate(node.deadline, t("locale.full"), {
								day: "2-digit",
								month: "short",
								year: "numeric",
								hour: "numeric",
								minute: "numeric",
								timeZoneName: "short",
								timeZone: DEFAULT_TIMEZONE
							})}
						</time>
					</div>

					{node.department && (
						<div className="department">
							<label className="show-for-sr">{t("Meta.label.department")}</label>
							<span>{node.department}</span>
						</div>
					)}
					{node.referenceNumber && (
						<div className="reference-number">
							<label className="show-for-sr">{t("Meta.label.referenceNumber")}</label>
							<span>{node.referenceNumber}</span>
						</div>
					)}
				</div>
			</article>
		);
	}
}
