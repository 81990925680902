import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { serializeForm, unserializeForm } from "common/utils.js";
import history from "skeleton/history.js";
import NewsSearchFormFields from "nodes/listing/NewsSearchFormFields.js";
import FaqSearchFormFields from "nodes/listing/FaqSearchFormFields.js";
import StaffMembersSearchFormFields from "nodes/listing/StaffMembersSearchFormFields.js";
import GuidesSearchFormFields from "nodes/listing/GuidesSearchFormFields.js";
import OperationsSearchFormFields from "nodes/listing/OperationsSearchFormFields.js";
import OperationsNewsSearchFormFields from "nodes/listing/OperationsNewsSearchFormFields.js";
import PublicationsSearchFormFields from "nodes/listing/PublicationsSearchFormFields.js";
import PublicRegisterSearchFormFields from "nodes/listing/PublicRegisterSearchFormFields.js";
import EventsSearchFormFields from "nodes/listing/EventsSearchFormFields.js";
import TendersClosedSearchFormFields from "nodes/listing/TendersClosedSearchFormFields.js";

export default class NodeListSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		list: PropTypes.object.isRequired,
		search: PropTypes.string
	};

	state = {
		selectedFiltersCount: 0
	};

	onSubmit = e => {
		e.preventDefault();
		this.updateFiltersCount();
		history.push("?" + serializeForm(this.formDomNode));
	};

	handleReset = e => {
		this.setState({ selectedFiltersCount: 0 });
		history.push("?");
	};

	enhanceDomForm = () => {
		// Possibly on a listing page without a form.
		if (!this.formDomNode) return;
		// Populate form with content from URL.
		unserializeForm(this.formDomNode, this.props.search);
		this.updateFiltersCount();
	};

	updateFiltersCount = () => {
		this.setState({ selectedFiltersCount: this.formDomNode.querySelectorAll("input:checked").length });
	};

	componentDidMount() {
		this.enhanceDomForm();
	}

	render() {
		const { t, list, search } = this.props;
		const { selectedFiltersCount } = this.state;
		const getFormFields = () => {
			if (list.id === "news")
				return (
					<NewsSearchFormFields
						t={t}
						types={list.filters.types}
						areas={list.filters.areas}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "faq")
				return (
					<FaqSearchFormFields
						t={t}
						categories={list.filters.categories}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "staff_members")
				return (
					<StaffMembersSearchFormFields
						t={t}
						areas={list.filters.areas}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "guides")
				return (
					<GuidesSearchFormFields
						t={t}
						areas={list.filters.areas}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "operations")
				return (
					<OperationsSearchFormFields
						t={t}
						areas={list.filters.areas}
						years={list.filters.years}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "operations_news")
				return (
					<OperationsNewsSearchFormFields
						t={t}
						areas={list.filters.areas}
						years={list.filters.years}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "publications_all")
				return (
					<PublicationsSearchFormFields
						t={t}
						types={list.filters.types}
						years={list.filters.years}
						status={list.filters.status}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "public_register")
				return (
					<PublicRegisterSearchFormFields
						t={t}
						types={list.filters.types}
						years={list.filters.years}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "events")
				return (
					<EventsSearchFormFields
						t={t}
						types={list.filters.types}
						years={list.filters.years}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
			if (list.id === "tenders_closed")
				return (
					<TendersClosedSearchFormFields
						t={t}
						types={list.filters.types}
						years={list.filters.years}
						search={search}
						selectedFiltersCount={selectedFiltersCount}
					/>
				);
		};
		const renderForm = () => {
			const fields = getFormFields();
			// Do not render form and action buttons if there are no filters.
			if (!React.Children.toArray(fields).length) return null;
			return (
				<form method="get" className="filters" onSubmit={this.onSubmit} ref={node => (this.formDomNode = node)}>
					<div className="fields">{fields}</div>
					<div className="actions">
						<button className="button" type="submit">
							{t("apply")}
						</button>
						<input className="button" type="reset" onClick={this.handleReset} value={t("reset")} />
					</div>
				</form>
			);
		};

		return <Fragment>{renderForm()}</Fragment>;
	}
}
