import PropTypes from "prop-types";
import { agreementPropTypes, agreementTeaserPropTypes } from "nodes/Agreement.js";
import { childAbusePropTypes, childAbuseTeaserPropTypes } from "nodes/ChildAbuse.js";
import { countryPropTypes, countryTeaserPropTypes } from "nodes/Country.js";
import { crimeAreasPropTypes, crimeAreasTeaserPropTypes } from "nodes/CrimeAreas.js";
import { documentPropTypes, documentTeaserPropTypes } from "nodes/Document.js";
import { etenderTeaserPropTypes } from "nodes/Etender.js";
import { eventPropTypes, eventTeaserPropTypes } from "nodes/Event.js";
import { faqPropTypes, faqTeaserPropTypes } from "nodes/Faq.js";
import { guidePropTypes, guideTeaserPropTypes } from "nodes/Guide.js";
import { homePagePropTypes } from "nodes/HomePage.js";
import { internshipPropTypes, internshipTeaserPropTypes } from "nodes/Internship.js";
import { multimediaPropTypes, multimediaTeaserPropTypes } from "nodes/Multimedia.js";
import { newsPropTypes, newsTeaserPropTypes } from "nodes/News.js";
import { operationPropTypes, operationTeaserPropTypes } from "nodes/Operation.js";
import { pagePropTypes, pageTeaserPropTypes } from "nodes/Page.js";
import { searchPropTypes } from "nodes/Search.js";
import { sitemapPropTypes } from "nodes/Sitemap.js";
import { staffMemberPropTypes, staffMemberTeaserPropTypes } from "nodes/StaffMember.js";
import { tenderPropTypes, tenderTeaserPropTypes } from "nodes/Tender.js";
import { timelinePropTypes } from "nodes/Timeline.js";
import { vacancyPropTypes, vacancyTeaserPropTypes } from "nodes/Vacancy.js";

export const nodePropTypes = PropTypes.oneOfType([
	agreementPropTypes,
	childAbusePropTypes,
	countryPropTypes,
	crimeAreasPropTypes,
	documentPropTypes,
	eventPropTypes,
	faqPropTypes,
	guidePropTypes,
	homePagePropTypes,
	internshipPropTypes,
	multimediaPropTypes,
	newsPropTypes,
	operationPropTypes,
	pagePropTypes,
	searchPropTypes,
	sitemapPropTypes,
	staffMemberPropTypes,
	tenderPropTypes,
	timelinePropTypes,
	vacancyPropTypes
]);

export const teaserNodePropTypes = PropTypes.arrayOf(
	PropTypes.oneOfType([
		agreementTeaserPropTypes,
		childAbuseTeaserPropTypes,
		countryTeaserPropTypes,
		crimeAreasTeaserPropTypes,
		documentTeaserPropTypes,
		etenderTeaserPropTypes,
		eventTeaserPropTypes,
		faqTeaserPropTypes,
		guideTeaserPropTypes,
		internshipTeaserPropTypes,
		multimediaTeaserPropTypes,
		newsTeaserPropTypes,
		operationTeaserPropTypes,
		pageTeaserPropTypes,
		staffMemberTeaserPropTypes,
		tenderTeaserPropTypes,
		vacancyTeaserPropTypes
	])
);
