import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Term from "common/Term.js";
import { renderDate, renderDateIso } from "common/utils.js";
import Wrapper from "common/Wrapper.js";
import { renderLink } from "common/Weblinks.js";
import { Link } from "react-router-dom";
import { EMPACT_TERM_ID, EMPACT_TERM_TARGET_PAGE_ALIAS } from "config.js";

export default class Meta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired // Should have used nodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
	};

	render() {
		const { t, node } = this.props;

		return (
			<Wrapper className="Meta" header={<h2>{t("Meta.heading")}</h2>}>
				<Wrapper htmlTag="ul">
					{node.eventType && (
						<li>
							<label>{t("Meta.label.eventType")}</label>
							<ul>
								{node.eventType.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.tenderDeadlineDate && (
						<li>
							<label>{t("Meta.label.tenderDeadlineDate")}</label>
							<time date={renderDateIso(node.tenderDeadlineDate)}>
								{renderDate(node.tenderDeadlineDate, t("locale.full"))}
							</time>
						</li>
					)}
					{node.tenderContractSignedDate && (
						<li>
							<label>{t("Meta.label.tenderContractSignedDate")}</label>
							<time date={renderDateIso(node.tenderContractSignedDate)}>
								{renderDate(node.tenderContractSignedDate, t("locale.full"))}
							</time>
						</li>
					)}
					{node.tenderContact && (
						<li>
							<label>{t("Meta.label.tenderContact")}</label>
							{renderLink(node.tenderContact)}
						</li>
					)}
					{node.tenderValue && (
						<li>
							<label>{t("Meta.label.tenderValue")}</label>
							<span className="tenderValue">
								<Term term={node.tenderValue} />
							</span>
						</li>
					)}
					{node.crimeAreas && (
						<li>
							<label>{t("Meta.label.crimeAreas")}</label>
							<ul>
								{node.crimeAreas.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.keywords && (
						<li>
							<label>{t("Meta.label.keywords")}</label>
							<ul>
								{node.keywords.map((term, index) => (
									<li key={index}>
										<span>
											{term.id === EMPACT_TERM_ID ? (
												<Link to={EMPACT_TERM_TARGET_PAGE_ALIAS}>{term.title}</Link>
											) : (
												term.title
											)}
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.services && (
						<li>
							<label>{t("Meta.label.services")}</label>
							<ul>
								{node.services.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.documentType && (
						<li>
							<label>{t("Meta.label.documentType")}</label>
							<ul>
								{node.documentType.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.articleType && (
						<li>
							<label>{t("Meta.label.articleType")}</label>
							<span>
								<Term term={node.articleType} />
							</span>
						</li>
					)}
					{node.agreementType && (
						<li>
							<label>{t("Meta.label.agreementType")}</label>
							<span>
								<Term term={node.agreementType} />
							</span>
						</li>
					)}
					{node.guideType && (
						<li>
							<label>{t("Meta.label.guideType")}</label>
							<span>
								<Term term={node.guideType} />
							</span>
						</li>
					)}
					{node.workAreas && (
						<li>
							<label>{t("Meta.label.workAreas")}</label>
							<ul>
								{node.workAreas.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.countries && (
						<li>
							<label>{t("Meta.label.countries")}</label>
							<ul>
								{node.countries.map((country, index) => (
									<li key={index}>
										<span>{country.name}</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.entities && (
						<li>
							<label>{t("Meta.label.entities")}</label>
							<ul>
								{node.entities.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.operations && (
						<li>
							<label>{t("Meta.label.operations")}</label>
							<ul>
								{node.operations.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.documentReportType && (
						<li>
							<label>{t("Meta.label.documentReportType")}</label>
							<span>
								<Term term={node.documentReportType} />
							</span>
						</li>
					)}
					{node.documentContent && (
						<li>
							<label>{t("Meta.label.documentContent")}</label>
							<span>
								<Term term={node.documentContent} />
							</span>
						</li>
					)}
					{node.documentSerialNumber && (
						<li>
							<label>{t("Meta.label.documentSerialNumber")}</label>
							<span>{node.documentSerialNumber}</span>
						</li>
					)}
					{node.organisations && (
						<li>
							<label>{t("Meta.label.organisations")}</label>
							<ul>
								{node.organisations.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
					{node.faqCategories && (
						<li>
							<label>{t("Meta.label.faqCategories")}</label>
							<ul>
								{node.faqCategories.map((term, index) => (
									<li key={index}>
										<span>
											<Term term={term} />
										</span>
									</li>
								))}
							</ul>
						</li>
					)}
				</Wrapper>
			</Wrapper>
		);
	}
}
