import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import TenderMeta from "common/TenderMeta.js";

export const etenderTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["etendering"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	status: PropTypes.string,
	reference: PropTypes.string,
	published: PropTypes.number,
	deadline: PropTypes.number
});

export class EtenderTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: etenderTeaserPropTypes
	};

	render() {
		const { t, node } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.status) extraCssClass += ` ${node.status}`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="content-wrapper">
					<h3>
						<a href={node.alias}>{node.title}</a>
					</h3>
					{node.status && (
						<div className="status">
							<label className="show-for-sr">{t("Meta.label.tenderStatus")}</label>
							<span>{node.status}</span>
						</div>
					)}
					<TenderMeta t={t} node={node} />
				</div>
			</article>
		);
	}
}
