import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { isExternalLink } from "common/utils.js";
import { NavLink } from "react-router-dom";

export const getBreadcrumbs = (menu, currentUrl) =>
	(Array.isArray(menu) ? menu : [menu]).reduce((acc, value) => {
		if (
			currentUrl === value.url ||
			(currentUrl.startsWith(value.url) && currentUrl.substr(value.url.length).startsWith("/"))
		)
			acc.push(value);
		if (value.children) acc = acc.concat(getBreadcrumbs(value.children, currentUrl));
		return acc;
	}, []);

export const renderMenuTree = elem =>
	Array.isArray(elem) ? (
		<ul>
			{elem.map((menuItem, index) => (
				<li key={index}>{renderMenuTree(menuItem)}</li>
			))}
		</ul>
	) : (
		<Fragment>
			{isExternalLink(elem.url) ? (
				<a href={elem.url} rel="external noopener noreferrer">
					{elem.name}
				</a>
			) : elem.url ? (
				<NavLink to={elem.url}>{elem.name}</NavLink>
			) : (
				<span>{elem.name}</span>
			)}
			{elem.children && elem.children.length && renderMenuTree(elem.children)}
		</Fragment>
	);

export const menuPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
		url: PropTypes.string,
		children: PropTypes.arrayOf(PropTypes.object) // Should have been PropTypes.arrayOf(menuPropTypes) actually.
	})
);

export default class Menu extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		menu: menuPropTypes
	};

	state = {
		activeParentMenuItem: undefined,
		activeSubMenuItems: []
	};

	setActiveParentMenuItem = id => {
		this.setState({ activeParentMenuItem: id });
	};

	setActiveSubMenuItem = id => {
		this.setState({ activeSubMenuItems: [...this.state.activeSubMenuItems, id] });
	};

	unsetActiveSubMenuItem = id => {
		this.setState({
			activeSubMenuItems: this.state.activeSubMenuItems.filter(item => item !== id)
		});
	};

	render() {
		const { t, menu } = this.props;

		// Do not render component if there is no menu at all.
		if (!menu || !menu.length) return null;

		const renderMenu = (t, elem, isSubMenu = false, parent = null) =>
			Array.isArray(elem) ? (
				isSubMenu ? (
					<div
						className={`menuWrapper ${
							parent && this.state.activeSubMenuItems.includes(parent.id) ? "open" : ""
						}`}
					>
						<ul>
							{parent && (
								<li className="back">
									<button onClick={() => this.unsetActiveSubMenuItem(parent.id)}>{t("back")}</button>
								</li>
							)}
							{parent && (
								<li className="parent">
									<NavLink to={parent.url}>{parent.name}</NavLink>
								</li>
							)}
							{elem.map((menuItem, index) => (
								<li key={index}>{renderMenu(t, menuItem)}</li>
							))}
						</ul>
					</div>
				) : (
					<ul>
						{elem.map((menuItem, index) => (
							<li
								key={index}
								className={menuItem.id === this.state.activeParentMenuItem ? "open" : ""}
								onMouseEnter={() => this.setActiveParentMenuItem(menuItem.id)}
								onMouseLeave={() => this.setActiveParentMenuItem()}
								onClick={() => this.setActiveParentMenuItem()}
							>
								{renderMenu(t, menuItem)}
							</li>
						))}
					</ul>
				)
			) : (
				<Fragment>
					{isExternalLink(elem.url) ? (
						<a
							href={elem.url}
							className={elem.children && "hasChildren"}
							rel="external noopener noreferrer"
						>
							{elem.name}
						</a>
					) : elem.url ? (
						<Fragment>
							<NavLink className={elem.children && "hasChildren"} to={elem.url}>
								{elem.name}
							</NavLink>
							{elem.children && (
								<button onClick={() => this.setActiveSubMenuItem(elem.id)}>{elem.name}</button>
							)}
						</Fragment>
					) : (
						<span>{elem.name}</span>
					)}
					{elem.children && elem.children.length > 0 && renderMenu(t, elem.children, true, elem)}
				</Fragment>
			);

		return (
			<nav role="navigation" className="Menu">
				{renderMenu(t, menu)}
			</nav>
		);
	}
}
