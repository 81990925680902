import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import history from "skeleton/history.js";
import Loading from "common/Loading.js";
import { loadNodeFromServer } from "skeleton/DataAccess.js";
import NodePanel from "nodes/NodePanel.js";
import { scrollTo } from "common/utils.js";
import { menuPropTypes } from "common/Menu.js";
import { trackPageView } from "config.js";
import { languagesPropTypes } from "common/Languages.js";

export default class NodeLoader extends PureComponent {
	state = {
		node: undefined,
		loadingStatus: undefined // ['loading', 'error-404', 'error-5xx', 'error-network']
	};

	static propTypes = {
		t: PropTypes.func.isRequired,
		url: PropTypes.string.isRequired,
		search: PropTypes.string,
		shouldLoadNode: PropTypes.bool.isRequired,
		blocks: PropTypes.object,
		menu: menuPropTypes.isRequired,
		languages: languagesPropTypes.isRequired
	};

	componentDidMount() {
		// ssr.php may have already provided the data we need.
		if (window.SERVER_DATA && window.SERVER_DATA.NodeLoader) {
			this.setState(window.SERVER_DATA.NodeLoader);
			delete window.SERVER_DATA.NodeLoader;
			return;
		}

		this.loadNode();
	}

	/**
	 * Loads the node from the server.
	 */
	loadNode = () => {
		const { url, search, shouldLoadNode } = this.props;

		// ESA-210 Redirect trailing "/" to non "/" URL.
		// ".+" in regex makes sure to exclude frontpage which is "/".
		if (url.match(/.+\/$/)) {
			console.log("Trailing slash found in URL. Moving to non slash URL.");
			history.replace(url.substr(0, url.length - 1));
			return;
		}

		if (!shouldLoadNode) {
			this.setState({ node: undefined });
			return;
		}

		this.setState({ loadingStatus: "loading" });

		// Keep track of a global incremental request id so we can ignore out of
		// date (stale) responses.
		const requestId = (this.lastRequestId = (this.lastRequestId || 0) + 1);
		loadNodeFromServer(url, search)
			.then(result => {
				if (requestId !== this.lastRequestId) return; // Ignore stale response.
				console.log(`XHR loaded for url: ${url}${search ? `, search: ${search || ""}` : ``}`);
				this.setState({ node: result, loadingStatus: undefined });
				scrollTo({ top: 0, behavior: "auto" });
				trackPageView();
			})
			.catch(error => {
				console.log(`XHR errored for url: ${url}${search ? `, search: ${search || ""}` : ``}`);
				if (error.response && error.response.status === 404) {
					this.setState({ node: undefined, loadingStatus: "error-404" });
				} else if (error.code === "ECONNABORTED" || !window.navigator.onLine) {
					this.setState({ node: undefined, loadingStatus: "error-network" });
				} else {
					this.setState({ node: undefined, loadingStatus: "error-5xx" });
				}
			});
	};

	closeLoading = () => {
		this.setState({ loadingStatus: undefined });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.url !== this.props.url || prevProps.search !== this.props.search) {
			console.log(
				"Page URL changed from " +
					prevProps.url +
					(prevProps.search ? "?" + prevProps.search : "") +
					" to " +
					this.props.url +
					(this.props.search ? "?" + this.props.search : "")
			);
			this.loadNode();
			return;
		}

		if (
			this.state.node &&
			prevState.node !== this.state.node &&
			window.location.hash &&
			window.location.hash.length > 1
		) {
			console.log("Rendered new content and URL contains hash. Refresh hash.");
			window.location = window.location.pathname + window.location.search + window.location.hash;
		}
	}

	render() {
		const { t, blocks, search, menu, languages } = this.props;
		const { node, loadingStatus } = this.state;

		return (
			<Fragment>
				{loadingStatus && (
					<Loading t={t} status={loadingStatus} retry={this.loadNode} close={this.closeLoading} />
				)}
				<NodePanel
					t={t}
					open={node !== undefined}
					node={node}
					blocks={blocks}
					search={search}
					menu={menu}
					languages={languages}
				/>
			</Fragment>
		);
	}
}
