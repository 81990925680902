import React, { Fragment, lazy, Suspense } from "react";
import { EU_MEMBER_STATES_CODES } from "config.js";
import { Link } from "react-router-dom";
import Wrapper from "common/Wrapper.js";
import NodeList from "nodes/listing/NodeList.js";
import { renderLink } from "common/Weblinks.js";

// Lazy load the world map SVG.
const WorldMapSvg = lazy(() => import("sass/images/WorldMapSvg.js"));

export const renderPageLists = (t, node, search) => {
	if (!node.lists || node.lists.length === 0) return null;

	// EPOLD8-188
	if (node.extraFunctionality && node.extraFunctionality.includes("MAP")) {
		return renderAgreementsPageLists(t, node);
	}

	return renderDefaultLists(t, node, search);
};

const renderDefaultLists = (t, node, search) =>
	node.lists.map((list, index) => <NodeList key={index} t={t} list={list} search={search} />);

const renderAgreementsPageLists = (t, node) => {
	const { lists } = node;

	const renderList = list =>
		list.items.length > 0 && (
			<div>
				<h3>{list.titleLink ? renderLink(list.titleLink, null, list.title) : list.title}</h3>
				<ul>
					{list.items.map((node, index) => (
						<li key={index}>
							<Link to={node.alias}>{node.title}</Link>
						</li>
					))}
				</ul>
			</div>
		);

	const renderLists = id =>
		lists.filter(list => list.id === id).map((list, index) => <Fragment key={index}>{renderList(list)}</Fragment>);

	const coloriseCountries = svg => {
		// Add EU member state class.
		svg.querySelectorAll(EU_MEMBER_STATES_CODES.map(code => `path.${code}`).join(",")).forEach(svgElement =>
			svgElement.classList.add("euMemberState")
		);
		// Add countries group index class.
		lists
			.filter(list => ["agreements_countries"].includes(list.id))
			.forEach((list, listIndex) =>
				list.items.forEach(
					item =>
						item.country &&
						item.country.code &&
						svg
							.querySelectorAll("." + item.country.code)
							.forEach(svgElement => svgElement.classList.add("countriesGroup" + (listIndex + 1)))
				)
			);
	};

	return (
		<div className="agreementsPageLists">
			<Wrapper
				className="countryAgreements"
				header={<h2>{t("Page.agreementsPageLists.countries")}</h2>}
				htmlTag="section"
			>
				<Suspense fallback={<p>{t("api.pending")}</p>}>
					<WorldMapSvg mutateSvg={coloriseCountries} />
				</Suspense>
				<div>{renderLists("agreements_countries")}</div>
			</Wrapper>
			<Wrapper
				className="organisationAgreements"
				header={<h2>{t("Page.agreementsPageLists.organisations")}</h2>}
				htmlTag="section"
			>
				{renderLists("agreements_organisations")}
			</Wrapper>
		</div>
	);
};
