import { PureComponent } from "react";
import PropTypes from "prop-types";
import { serializeFormToFormData } from "common/utils.js";
import { submitForm } from "skeleton/DataAccess.js";

export const formPropTypes = PropTypes.exact({
	id: PropTypes.string.isRequired,
	metadata: PropTypes.exact({
		title: PropTypes.string.isRequired,
		fields: PropTypes.arrayOf(
			PropTypes.exact({
				name: PropTypes.string.isRequired,
				type: PropTypes.oneOf([
					"text",
					"email",
					"textarea",
					"radio",
					"select",
					"checkbox",
					"file",
					"number",
					"markup",
					"markup-heading"
				]).isRequired,
				label: PropTypes.string.isRequired,
				maxlength: PropTypes.number,
				min: PropTypes.number,
				max: PropTypes.number,
				reply: PropTypes.bool,
				required: PropTypes.bool.isRequired,
				accept: PropTypes.arrayOf(PropTypes.string),
				allowedExtensions: PropTypes.arrayOf(PropTypes.string),
				options: PropTypes.arrayOf(
					PropTypes.exact({ key: PropTypes.string.isRequired, label: PropTypes.string.isRequired })
				)
			}).isRequired
		),
		uploadMaxSize: PropTypes.number
	}).isRequired
});

export default class Form extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		form: formPropTypes,
		url: PropTypes.string.isRequired
	};

	state = {
		submissionStatus: undefined // ['warning-uploadMaxSize', 'pending', 'success', 'error']
	};

	shouldFormBeDisabled = () => ["warning-uploadMaxSize", "pending"].includes(this.state.submissionStatus);

	// enhanceDomForm = () => {
	// 	// Check if form exists because we may have redirected to the login page via the render function.
	// 	if (this.formDom) {
	// 		// Apply autosize to auto grow textareas.
	// 		this.formDom.querySelectorAll("textarea[name]").forEach(textarea => autosize(textarea));
	// 	}
	// };

	componentDidMount() {
		// this.enhanceDomForm();
	}

	onSubmit = e => {
		e.preventDefault();
		const formData = serializeFormToFormData(this.formDom, true);
		this.setState({ submissionStatus: "pending" });
		submitForm(this.props.form.id, this.props.url, formData)
			.then(() => {
				this.setState({ submissionStatus: "success" });
			})
			.catch(() => {
				this.setState({ submissionStatus: "error" });
			});
	};

	/**
	 * Calculates total post size and warns user if it has exceeded what the server
	 * is wiling to accept.
	 */
	checkFormPostSize = () => {
		let total = 0;
		this.formDom.querySelectorAll("input[name][type=file]").forEach(field => {
			Array.from(field.files).forEach(file => {
				total += file.size;
			});
		});
		if (total >= this.props.form.metadata.uploadMaxSize) {
			this.setState({ submissionStatus: "warning-uploadMaxSize" });
		} else {
			// Reset status only if previous status was the uploadMaxSize warning.
			if (this.state.submissionStatus === "warning-uploadMaxSize") {
				this.setState({ submissionStatus: undefined });
			}
		}
	};

	render() {
		const { t, form } = this.props;
		const { submissionStatus } = this.state;

		const renderField = field => (
			<div key={field.name} className={`field-type-${field.type} field-name-${field.name}`}>
				{/* checkbox is the only one that, for styling reasons, requires the input before the label */}
				{field.type === "checkbox" && (
					<input
						id={`field-name-${field.name}`}
						type="checkbox"
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type !== "markup" && field.type !== "markup-heading" && (
					<label htmlFor={`field-name-${field.name}`}>
						{field.label}
						{field.required && (
							<span className="required" aria-hidden="true">
								*
							</span>
						)}
					</label>
				)}
				{field.type === "text" && (
					<input
						id={`field-name-${field.name}`}
						type="text"
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type === "email" && (
					<input
						id={`field-name-${field.name}`}
						type="email"
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type === "number" && (
					<input
						id={`field-name-${field.name}`}
						type="number"
						min={field.min}
						max={field.max}
						name={field.name}
						required={field.required}
					/>
				)}
				{field.type === "textarea" && (
					<textarea
						id={`field-name-${field.name}`}
						maxLength={field.maxlength}
						name={field.name}
						required={field.required}
						rows="1" // in order to have the same height as the inputs
					/>
				)}
				{field.type === "radio" &&
					field.options.map((option, index) => (
						<label key={index} className="radio-option">
							<input type="radio" name={field.name} required={field.required} value={option.key} />
							<span>{option.label}</span>
						</label>
					))}

				{field.type === "select" && (
					<select id={`field-name-${field.name}`} name={field.name} required={field.required}>
						<option value=""></option>
						{field.options.map((option, index) => (
							<option key={index} value={option.key}>
								{option.label}
							</option>
						))}
					</select>
				)}
				{field.type === "file" && (
					<input
						id={`field-name-${field.name}`}
						type="file"
						name={field.name}
						required={field.required}
						accept={field.accept.join(",")}
						onChange={this.checkFormPostSize}
					/>
				)}
				{field.type === "markup-heading" && <h3>{field.label}</h3>}
				{field.type === "markup" && <p>{field.label}</p>}
			</div>
		);

		const renderForm = fields => (
			<form
				method="post"
				className={`Form ${form.id}`}
				onSubmit={this.onSubmit}
				ref={form => (this.formDom = form)}
			>
				<fieldset>
					<h2 className="show-for-sr">{form.metadata.title}</h2>
					{submissionStatus !== "success" && <div className="fields">{fields}</div>}
					{submissionStatus === "warning-uploadMaxSize" && (
						<div className="message warning">
							<p>
								{t("Form.submissionStatus.warning-uploadMaxSize", {
									uploadMaxSize: form.metadata.uploadMaxSize
								})}
							</p>
						</div>
					)}
					{submissionStatus === "pending" && (
						<div className="message pending">
							<p>{t("Form.submissionStatus.pending")}</p>
						</div>
					)}
					{submissionStatus === "success" && (
						<div className="message success">
							<p>{t("Form.submissionStatus.success")}</p>
						</div>
					)}
					{submissionStatus === "error" && (
						<div className="message error">
							<p>{t("Form.submissionStatus.error")}</p>
						</div>
					)}
					<div className="actions">
						{submissionStatus !== "success" && (
							<button className="button" type="submit" disabled={this.shouldFormBeDisabled()}>
								{t("Form.submit")}
							</button>
						)}
					</div>
				</fieldset>
			</form>
		);

		return renderForm(form.metadata.fields.map(field => renderField(field)));
	}
}
