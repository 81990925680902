import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { MultimediaTeaser } from "nodes/Multimedia.js";

export default class MultimediaViewNodeList extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		list: PropTypes.object.isRequired
	};

	getSlidesToShow = size => {
		if (size === "large") return 3;
		if (size === "med") return 2;
		if (size === "small") return 1;
		return 1;
	};

	render() {
		const { t, list } = this.props;

		// To collect all departments we have in the results.
		const categories = [];

		// Group staff members.
		const multimediaPerCategory = list.items.reduce((map, node) => {
			if (!map[node.multimediaCategory.id]) map[node.multimediaCategory.id] = [];
			map[node.multimediaCategory.id].push(node);
			// Collect departments.
			if (!categories.find(multimediaCategory => multimediaCategory.id === node.multimediaCategory.id)) {
				categories.push(node.multimediaCategory);
			}
			return map;
		}, {});
		return (
			<Fragment>
				{categories
					.sort((a, b) => a.weight - b.weight)
					.map((category, index) => (
						<Fragment key={`d-${index}`}>
							<h2>{category.title}</h2>
							<ul key={`s-${index}`} className="layout">
								{multimediaPerCategory[category.id].map((node, index) => (
									<li key={index}>
										<MultimediaTeaser t={t} node={node} />
									</li>
								))}
							</ul>
						</Fragment>
					))}
			</Fragment>
		);
	}
}
