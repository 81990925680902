import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import Weblinks, { renderLink } from "common/Weblinks.js";
import RelatedContent from "common/RelatedContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import { Link } from "react-router-dom";
import { renderDate, renderDateIso } from "common/utils.js";
import Wrapper from "common/Wrapper.js";

export const countryPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["country"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	body: PropTypes.string,
	summary: PropTypes.string,
	weblinks: weblinksPropTypes,
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	subtitle: PropTypes.string,
	files: downloadsPropTypes,
	relatedContent: PropTypes.array, // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	country: PropTypes.exact({
		code: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	}).isRequired,
	countryCapital: PropTypes.string,
	countryArea: PropTypes.string,
	countryPopulation: PropTypes.string,
	countryAgencies: PropTypes.arrayOf(
		PropTypes.exact({
			id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired,
			mainImage: imagePropTypes,
			description: PropTypes.string,
			emails: PropTypes.arrayOf(PropTypes.string),
			phones: PropTypes.arrayOf(PropTypes.string),
			websites: weblinksPropTypes
		})
	)
});

export const countryTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["country"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	mainImage: imagePropTypes
});

export default class Country extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: countryPropTypes
	};

	renderCountryAgencies = () => {
		const { t, node } = this.props;
		if (!node.countryAgencies || node.countryAgencies.length === 0) return null;
		return (
			<Fragment>
				<div className="countryAgencies layout grey curve">
					<div>
						<h2>{t("Country.countryAgencies.label")}</h2>
						<ul>
							{node.countryAgencies.map((agency, index) => (
								<li key={index}>
									{agency.mainImage && <Image image={agency.mainImage} type="full" />}
									<h3>{agency.title}</h3>
									{agency.description && <ClickableDrupalContent content={agency.description} />}
									<Wrapper
										className="contactDetails"
										header={<h4>{t("Country.countryAgencies.contactDetails")}</h4>}
									>
										<Wrapper className="layout columns-3">
											{agency.emails && (
												<div className="email">
													<h5>{t("Country.countryAgencies.email")}</h5>
													<ul>
														{agency.emails.map((email, index) => (
															<li key={index}>
																<span>{email}</span>
															</li>
														))}
													</ul>
												</div>
											)}
											{agency.phones && (
												<div className="phone">
													<h5>{t("Country.countryAgencies.phone")}</h5>
													<ul>
														{agency.phones.map((phone, index) => (
															<li key={index}>
																<span>{phone}</span>
															</li>
														))}
													</ul>
												</div>
											)}
											{agency.websites && (
												<div className="websites">
													<h5>{t("Country.countryAgencies.websites")}</h5>
													<ul>
														{agency.websites.map((link, index) => (
															<li key={index}>{renderLink(link)}</li>
														))}
													</ul>
												</div>
											)}
										</Wrapper>
									</Wrapper>
								</li>
							))}
						</ul>
					</div>
				</div>
			</Fragment>
		);
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Wrapper className="countryInfo">
					<h2 className="show-for-sr">{t("Country.countryInfo")}</h2>
					<ul>
						{node.countryCapital && (
							<li>
								<label>{t("Country.capital")}</label>
								{node.countryCapital}
							</li>
						)}
						{node.countryArea && (
							<li>
								<label>{t("Country.area")}</label>
								{node.countryArea}
							</li>
						)}
						{node.countryPopulation && (
							<li>
								<label>{t("Country.population")}</label>
								{node.countryPopulation}
							</li>
						)}
					</ul>
				</Wrapper>
				{this.renderCountryAgencies()}
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class CountryTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: countryTeaserPropTypes,
		relatedContentView: PropTypes.bool
	};

	static defaultProps = {
		relatedContentView: false // Set different image size in case that appears on related content list EPOLD8-291.
	};

	render() {
		const { t, node, relatedContentView } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && (
						<Image image={node.mainImage} type={relatedContentView ? "related_content" : "teaser"} />
					)}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					<div className="publish-date">
						<label className="show-for-sr">{t("Meta.label.date")}</label>
						<time date={renderDateIso(node.published)}>{renderDate(node.published, t("locale.full"))}</time>
					</div>
					<Meta t={t} node={node} />
				</div>
			</article>
		);
	}
}
