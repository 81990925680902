import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import Wrapper from "common/Wrapper.js";
import Slider from "react-slick";
import Image, { imagePropTypes } from "common/Image.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { linkPropTypes, renderLink } from "common/Weblinks.js";
import { Link } from "react-router-dom";
import { NewsTeaser, newsTeaserPropTypes } from "nodes/News.js";
import { DocumentTeaser, documentTeaserPropTypes } from "nodes/Document.js";
import { GuideTeaser, guideTeaserPropTypes } from "nodes/Guide.js";
import DateRange from "common/DateRange.js";
import { renderDate, renderDateIso, renderUrl } from "common/utils.js";
import { DEFAULT_TIMEZONE, DEFAULT_LANGUAGE, LANGUAGE_SWITCHER_TARGET_PAGE_ALIAS } from "config.js";
import MagicGrid from "react-magic-grid";
import { languagesPropTypes } from "common/Languages.js";
import history from "skeleton/history.js";

export const homePagePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["home_page"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	summary: PropTypes.string,
	body: PropTypes.string,
	areas: PropTypes.exact({
		banners: PropTypes.arrayOf(
			PropTypes.exact({
				id: PropTypes.number.isRequired,
				type: PropTypes.oneOf(["banner"]).isRequired,
				title: PropTypes.string.isRequired,
				body: PropTypes.string,
				mainImage: PropTypes.oneOfType([imagePropTypes, PropTypes.string]).isRequired,
				contentLink: linkPropTypes
			})
		),
		news: PropTypes.exact({
			title: PropTypes.string.isRequired,
			options: PropTypes.arrayOf(
				PropTypes.exact({
					title: PropTypes.string,
					items: PropTypes.arrayOf(newsTeaserPropTypes).isRequired
				})
			),
			viewAllLink: PropTypes.string
		}),
		documents: PropTypes.exact({
			items: PropTypes.arrayOf(documentTeaserPropTypes).isRequired,
			viewAllLink: PropTypes.string
		}),
		guides: PropTypes.exact({
			items: PropTypes.arrayOf(guideTeaserPropTypes).isRequired,
			viewAllLink: PropTypes.string
		}),
		centres: PropTypes.exact({
			links: PropTypes.arrayOf(
				PropTypes.exact({
					title: PropTypes.string.isRequired,
					link: PropTypes.string.isRequired
				})
			)
		}),
		updates: PropTypes.arrayOf(
			PropTypes.exact({
				title: PropTypes.string.isRequired,
				items: PropTypes.arrayOf(
					PropTypes.exact({
						id: PropTypes.number.isRequired,
						type: PropTypes.oneOf(["vacancy", "event", "tender"]).isRequired,
						title: PropTypes.string.isRequired,
						alias: PropTypes.string.isRequired,
						startDate: PropTypes.number,
						endDate: PropTypes.number,
						tenderDeadlineDate: PropTypes.number,
						deadline: PropTypes.number
					})
				).isRequired,
				viewAllLink: PropTypes.string,
				links: PropTypes.arrayOf(
					PropTypes.exact({
						title: PropTypes.string.isRequired,
						link: PropTypes.string.isRequired
					})
				)
			})
		),
		crimeAreas: PropTypes.arrayOf(
			PropTypes.exact({
				id: PropTypes.number.isRequired,
				title: PropTypes.string.isRequired,
				alias: PropTypes.string.isRequired
			})
		)
	}).isRequired
});

export default class HomePage extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: homePagePropTypes,
		languages: languagesPropTypes.isRequired
	};

	state = {
		selectedNewsOptionIndex: 0,
		selectedUpdatesSectionIndex: 0
	};

	renderLanguages = () => {
		const { t, node, languages } = this.props;
		return (
			<label className="dropdown-filter">
				<span>{t("HomePage.language")}</span>
				<select
					onChange={e => history.push(renderUrl(LANGUAGE_SWITCHER_TARGET_PAGE_ALIAS, e.target.value))}
					defaultValue={node.language || DEFAULT_LANGUAGE}
				>
					{languages.map((language, index) => (
						<option key={index} value={language.code}>
							{language.code} - {language.title}
						</option>
					))}
				</select>
			</label>
		);
	};

	renderBanners = () => {
		const { t, node } = this.props;
		const banners = node.areas.banners;
		return (
			<div className="banners">
				<h2 className="show-for-sr">{t("HomePage.areas.banners")}</h2>
				<Slider arrows={false} dots={true} autoplay={true}>
					{banners.map(banner => (
						<article key={banner.id}>
							{banner.mainImage.thumbs ? (
								<div className="image-wrapper">
									<Image image={banner.mainImage} type="banner" />
								</div>
							) : (
								<div className="video-wrapper">
									<iframe
										title={banner.title}
										width="560"
										height="315"
										src={banner.mainImage}
										frameBorder="0"
										allowFullScreen
										loading="lazy"
									></iframe>
								</div>
							)}
							<h3>
								{banner.contentLink ? renderLink(banner.contentLink, null, banner.title) : banner.title}
							</h3>
							{banner.body && <ClickableDrupalContent content={banner.body} />}
						</article>
					))}
				</Slider>
			</div>
		);
	};

	renderNews = () => {
		const { t, node } = this.props;
		const news = node.areas.news;
		const { selectedNewsOptionIndex } = this.state;
		return (
			<div className="news">
				<h2>{t("HomePage.areas.news")}</h2>
				<div>
					<label className="dropdown-filter">
						<span>{news.title}</span>
						<select onChange={e => this.setState({ selectedNewsOptionIndex: e.target.value })}>
							{news.options.map((option, index) => (
								<option value={index} key={index}>
									{option.title}
								</option>
							))}
						</select>
					</label>
					{news.viewAllLink && (
						<Link to={news.viewAllLink} className="button orange large">
							{t("view_all_newsroom")}
						</Link>
					)}
				</div>
				{news.options[selectedNewsOptionIndex].items.length > 0 && (
					<div className="newsMagicGrid">
						<MagicGrid static animate={false} gutter={0} as="ul">
							{news.options[selectedNewsOptionIndex].items.map(node => (
								<li key={node.id}>
									<NewsTeaser t={t} node={node} />
								</li>
							))}
						</MagicGrid>
					</div>
				)}
			</div>
		);
	};

	renderDocuments = () => {
		const { t, node } = this.props;
		const documents = node.areas.documents;
		const maxSlidesToShow = 5;

		const sliderSettings = {
			arrows: false,
			dots: true,
			// to prevent https://github.com/kenwheeler/slick/issues/940
			// also: https://github.com/akiran/react-slick/issues/1171
			infinite: documents.length > maxSlidesToShow,
			speed: 500,
			slidesToShow: maxSlidesToShow,
			slidesToScroll: maxSlidesToShow,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 420,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		return (
			<div>
				<h2>{t("HomePage.areas.documents")}</h2>
				<Slider {...sliderSettings}>
					{documents.items.map(node => (
						<DocumentTeaser key={node.id} t={t} node={node} />
					))}
				</Slider>
				{documents.viewAllLink && (
					<Link to={documents.viewAllLink} className="button">
						{t("view_all")}
					</Link>
				)}
			</div>
		);
	};

	renderGuides = () => {
		const { t, node } = this.props;
		const guides = node.areas.guides;
		const maxSlidesToShow = 6;

		const sliderSettings = {
			arrows: false,
			dots: true,
			// to prevent https://github.com/kenwheeler/slick/issues/940
			// also: https://github.com/akiran/react-slick/issues/1171
			infinite: guides.length > maxSlidesToShow,
			speed: 500,
			slidesToShow: maxSlidesToShow,
			slidesToScroll: maxSlidesToShow,
			responsive: [
				{
					breakpoint: 1440,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 420,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		return (
			<div>
				<h2>{t("HomePage.areas.guides")}</h2>
				<Slider {...sliderSettings}>
					{guides.items.map(node => (
						<GuideTeaser key={node.id} t={t} node={node} />
					))}
				</Slider>
				{guides.viewAllLink && (
					<Link to={guides.viewAllLink} className="button">
						{t("view_all")}
					</Link>
				)}
			</div>
		);
	};

	renderCentres = () => {
		const { t, node } = this.props;
		const centres = node.areas.centres;
		return (
			<div>
				<div>
					<h2>{t("HomePage.areas.centres")}</h2>
				</div>
				<div>
					<ul>
						{centres.links.map((link, index) => (
							<li key={index}>
								<article className="NodeTeaser centre">
									<div className="content-wrapper">
										<h3>
											<Link to={link.link}>{link.title}</Link>
										</h3>
									</div>
								</article>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	};

	renderUpdates = () => {
		const { t, node } = this.props;
		const updates = node.areas.updates;
		const { selectedUpdatesSectionIndex } = this.state;
		const selectedUpdatesSection = updates[selectedUpdatesSectionIndex];
		return (
			<div>
				<h2>{t("HomePage.areas.updates")}</h2>
				<h3 className="show-for-sr">{t("filters")}</h3>
				<ul className="filters">
					{updates.map((update, index) => (
						<li key={index}>
							<button
								value={index}
								className={`button ${update.title.toLowerCase()}${
									index === selectedUpdatesSectionIndex ? " active" : ""
								}`}
								onClick={e => this.setState({ selectedUpdatesSectionIndex: index })}
							>
								{update.title}
							</button>
						</li>
					))}
				</ul>
				{selectedUpdatesSection.items.length > 0 && (
					<div className="results">
						<MagicGrid static animate={false} gutter={0} as="ul">
							{selectedUpdatesSection.items.map(node => (
								<li key={node.id}>
									<article className={`ct_${node.type}`}>
										<h3>
											<Link to={node.alias}>{node.title}</Link>
										</h3>
										{node.startDate && (
											<div>
												<DateRange t={t} startDate={node.startDate} endDate={node.endDate} />
											</div>
										)}
										{node.documentSignatureDate && (
											<div>
												<label>{`${t("Meta.label.documentSignatureDate")}: `}</label>
												<time date={renderDateIso(node.documentSignatureDate)}>
													{renderDate(node.documentSignatureDate, t("locale.full"))}
												</time>
											</div>
										)}
										{node.tenderDeadlineDate && (
											<div>
												<label>{t("Meta.label.tenderDeadlineDate")}</label>
												{": "}
												<time date={renderDateIso(node.tenderDeadlineDate)}>
													{renderDate(node.tenderDeadlineDate, t("locale.full"))}
												</time>
											</div>
										)}
										{node.deadline && (
											<div>
												<label>{t("Meta.label.deadline")}</label>
												{": "}
												<time date={renderDateIso(node.deadline, 24)}>
													{renderDate(node.deadline, t("locale.full"), {
														day: "2-digit",
														month: "short",
														year: "numeric",
														hour: "numeric",
														minute: "numeric",
														timeZoneName: "short",
														timeZone: DEFAULT_TIMEZONE
													})}
												</time>
											</div>
										)}
										<div>{t("NodeType." + node.type)}</div>
									</article>
								</li>
							))}
						</MagicGrid>
					</div>
				)}
				{selectedUpdatesSection.viewAllLink && (
					<div className="inline-links">
						<Link to={selectedUpdatesSection.viewAllLink} className="button">
							{t("view_all")}
						</Link>
					</div>
				)}
				{selectedUpdatesSection.links && selectedUpdatesSection.links.length > 0 && (
					<div className="inline-links">
						{selectedUpdatesSection.links.map((link, index) => (
							<Link key={index} to={link.link} className="button">
								{link.title}
							</Link>
						))}
					</div>
				)}
			</div>
		);
	};

	renderCrimeAreas = () => {
		const { t, node } = this.props;
		return (
			<div>
				<div>
					<h2>{t("HomePage.areas.crimeAreas")}</h2>
				</div>
				<div>
					<ul>
						{node.areas.crimeAreas.map(crimeArea => (
							<li key={crimeArea.id}>
								<Link to={crimeArea.alias}>{crimeArea.title}</Link>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<h1>{t("title")}</h1>

				<section className="bannersNews layout margins">
					<div>
						{node.areas && node.areas.banners && this.renderBanners()}
						{this.renderLanguages()}
					</div>
					{node.areas && node.areas.news && node.areas.news.options && this.renderNews()}
				</section>
				{node.body && (
					<section className="ads layout dark-blue curve column-width">
						<ClickableDrupalContent content={node.body} />
					</section>
				)}
				<Wrapper className="documents layout grey curve margins" htmlTag="section">
					{node.areas && node.areas.documents && node.areas.documents.items && this.renderDocuments()}
				</Wrapper>
				<Wrapper className="guides layout curve margins" htmlTag="section">
					{node.areas && node.areas.guides && node.areas.guides.items && this.renderGuides()}
				</Wrapper>
				<Wrapper className="centres layout grey curve margins" htmlTag="section">
					{node.areas && node.areas.centres && this.renderCentres()}
				</Wrapper>
				<Wrapper className="updates layout grey curve margins" htmlTag="section">
					{node.areas && node.areas.updates && this.renderUpdates()}
				</Wrapper>
				<Wrapper className="crimeAreas layout curve" htmlTag="section">
					{node.areas && node.areas.crimeAreas && this.renderCrimeAreas()}
				</Wrapper>
			</Fragment>
		);
	}
}
