import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Block from "common/Block.js";
import { Link } from "react-router-dom";

export default class AppFooter extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		blocks: PropTypes.object.isRequired
	};

	render() {
		const { t, blocks } = this.props;
		return (
			<footer className="AppFooter">
				<div className="footer-content">
					<Block name="footer-top" blocks={blocks} />
					<div className="footer-bottom">
						<div className="logo">
							<Link to="/">{t("title")}</Link>
							<Block name="footer-bottom-first" blocks={blocks} />
						</div>
						<Block name="footer-bottom-second" blocks={blocks} />
						<Block name="footer-bottom-third" blocks={blocks} />
					</div>
				</div>
			</footer>
		);
	}
}
