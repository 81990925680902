import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { imagePropTypes } from "common/Image.js";
import { weblinksPropTypes } from "common/Weblinks.js";

export const termPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string,
	redirectUrl: PropTypes.string
});

export const countryPropTypes = PropTypes.exact({
	code: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
});

export const organisationPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string,
	mainImage: imagePropTypes,
	website: weblinksPropTypes
});

export default class Term extends PureComponent {
	static propTypes = {
		term: termPropTypes.isRequired
	};

	render() {
		const { term } = this.props;
		if (term.redirectUrl) return <Link to={term.redirectUrl}>{term.title}</Link>;
		return term.alias ? <Link to={term.alias}>{term.title}</Link> : term.title;
	}
}
