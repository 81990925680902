const i18n = {
	locale: "en",
	"locale.full": "en-GB",
	title: "Europol",
	retry: "Retry",
	"api.error": "An error has occured while communicating with the server. Please try again.",
	"api.network": "The request timed out or you are currently offline. Please try again.",
	"api.unauthorized": "You are currently not logged in.",
	"api.pending": "Please wait...",
	deleted: "Deleted",
	edited: "Edited",
	edit: "Edit",
	save: "Save",
	more: "More...",
	less: "Less...",
	next: "Next",
	previous: "Previous",
	reset: "Reset",
	apply: "Apply",
	close: "Close",
	cancel: "Cancel",
	back: "Back",
	login: "Login",
	home: "Home",
	view_all: "View all",
	view_all_newsroom: "Newsroom",
	results: "Results",
	items: "(%{items} items)",
	items_more: "(more than %{items} items)",
	filters: "Filters",
	no_results: "There are no results that match the selected criteria.",
	promoted: "promoted",
	forthcoming: "forthcoming",
	ago: {
		future: "in the future",
		now: "just now",
		seconds: "just now||||%{smart_count} seconds ago",
		minutes: "a minute ago||||%{smart_count} minutes ago",
		hours: "an hour ago||||%{smart_count} hours ago",
		days: "yesterday||||%{smart_count} days ago",
		months: "1 month ago||||%{smart_count} months ago",
		years: "1 year ago||||%{smart_count} years ago"
	},
	Loading: {
		loading: "Loading...",
		error401: {
			header: "401 Unauthorized",
			subheader: "We are sorry!",
			content: "Please login so you can access this content."
		},
		error404: {
			header: "404 Error",
			subheader: "Oops, seems you are lost!",
			content: "We can't seem to find the content you are looking for.",
			homeButton: "Back to Europol Home Page ",
			searchButton: "Search the website"
		},
		error5xx: {
			header: "Sorry. It's not you, it's us.",
			content:
				"The server encountered an internal error or misconfiguration and was unable to complete your request."
		},
		errorNetwork: {
			header: "Network error!",
			content: "The request timed out or you are currently offline."
		}
	},
	LocaleSwitcher: "Language",
	Languages: {
		aria: "Languages",
		heading: "Available in:"
	},
	AppHeader: {
		menu: "Menu",
		search: "Search",
		contact: "Contact",
		language: "Language"
	},
	Meta: {
		heading: "Tags",
		label: {
			crimeAreas: "Crime areas",
			keywords: "Keywords",
			published: "Publish date",
			updated: "Update date",
			countries: "Participating Countries",
			operations: "Operations",
			date: "Date",
			dateRange: "Duration",
			services: "Services",
			documentType: "Document type",
			articleType: "Article type",
			entities: "Entities",
			tenderStatus: "Status",
			tenderType: "Type",
			tenderValue: "Value",
			tenderNumber: "Number",
			tenderDeadlineDate: "Deadline",
			tenderContractSignedDate: "Signed date",
			tenderAwarded: "Awarded",
			tenderContact: "Contact",
			documentReportType: "Report type",
			documentContent: "Content",
			documentSerialNumber: "Serial number",
			organisations: "Organisations",
			faqCategories: "Categories",
			guideType: "Type",
			workAreas: "Work areas",
			deadline: "Deadline",
			postType: "Type",
			referenceNumber: "Reference number",
			contractType: "Contract type",
			department: "Department",
			eventType: "Type",
			categories: "Categories",
			agreementType: "Agreement type",
			documentForceDate: "Entry into force date",
			documentSignatureDate: "Signature date",
			documentRatificationDate: "Ratification date"
		}
	},
	NodeType: {
		label: "Content type",
		agreement: "Agreement",
		child_abuse: "Child Abuse",
		country: "Country",
		crime_areas: "Crime Areas",
		document: "Document",
		event: "Event",
		faq: "Faq",
		guide: "Guide",
		internship: "Internship",
		multimedia: "Multimedia",
		news: "News",
		operation: "Operation",
		page: "Page",
		search: "Search",
		sitemap: "Sitemap",
		staff_member: "Staff Member",
		tender: "Tender",
		timeline: "Timeline",
		vacancy: "Vacancy"
	},
	Weblinks: {
		label: "Links"
	},
	Downloads: {
		label: "Downloads",
		otherLanguagesLabel: "Other languages"
	},
	Empact: {
		heading: "Empact",
		label: "Part of the EMPACT Cycle"
	},
	RelatedContent: {
		heading: {
			default: "Related content",
			featured_operations: "Featured operations"
		}
	},
	EventTopMeta: {
		dateLabel: "When",
		locationLabel: "Where",
		eventVenueLink: "Venue",
		eventRegistrationLink: "Registration"
	},
	NodeListSearchForm: {
		search: "Type a term...",
		filters: {
			heading: "Filters",
			open: "Filter",
			year: "Year",
			type: "Type",
			category: "Category",
			area: "Area",
			counter: "selected filters",
			status: {
				label: "Status",
				published: "Published",
				forthcoming: "Forthcoming"
			}
		}
	},
	HomePage: {
		areas: {
			banners: "Featured",
			news: "News",
			documents: "Publications & Projects",
			guides: "Prevention Guides",
			centres: "Centres",
			updates: "Updates",
			crimeAreas: "Crime Areas"
		},
		language: "Learn more about Europol in"
	},
	Page: {
		agreementsPageLists: {
			countries: "Agreements & working arrangements with countries",
			organisations: "Agreements & working arrangements with agencies, institutions & organisations",
			worldMap: "World map"
		},
		newsletterSubscriptionManagement: {
			email: "Enter your e-mail address",
			disclaimer: {
				title: "Disclaimer",
				body: "Your request will be handled by Europol in accordance with the provisions of Regulation (EU) 2018/1725 of the European Parliament and of the Council of 23 October 2018 on the protection of natural persons with regard to the processing of personal data by the Union institutions, bodies, offices and agencies and on the free movement of such data, and repealing Regulation (EC) No 45/2001 and Decision No 1247/2002/EC applicable to all administrative personal data held by Europol in accordance with Article 46 of the Europol Regulation.\n\nYour data will be stored in the database until you unsubscribe from the service.You can modify your data at any time by changing your e-mail, your subscriptions or even completely unsubscribe from the service. In this case, all your data will be erased from our database. More information on Europol's legal commitment with respect to users privacy can be found on our legal notice page.",
				link: "legal notice",
				accept: "I accept the terms and conditions"
			},
			requestSubscribe: {
				button: "Subscribe",
				success:
					"You will receive a confirmation e-mail shortly containing further instructions on how to complete your subscription."
			},
			confirmSubscribe: {
				success: "Thanks. Your newsletter subscription is confirmed.",
				error: "The link was either expired or invalid. Please try again."
			},
			requestUnsubscribe: {
				button: "Unsubscribe",
				success:
					"You will receive a confirmation e-mail shortly containing further instructions on how to cancel your subscription.",
				error404: "There are no e-mail alerts subscriptions for the specified email address."
			},
			confirmUnsubscribe: {
				success: "Thanks. Your newsletter subscription has been cancelled.",
				error: "The link was either expired or invalid. Please try again."
			}
		}
	},
	Country: {
		countryInfo: "Country Information",
		countryAgencies: {
			label: "Law Enforcement Agencies",
			email: "Email",
			phone: "Phone",
			contactDetails: "Contact Details",
			websites: "Links"
		},
		capital: "Capital",
		area: "Area",
		population: "Population"
	},
	Form: {
		required: "Required field",
		submit: "Submit",
		submissionStatus: {
			"warning-uploadMaxSize":
				"The maximum total size of files submitted should not exceed %{uploadMaxSize} bytes. Please remove some files and try again.",
			pending: "Submitting. Please wait...",
			success: "Thank you! Your submission has been received.",
			error: "We're sorry, something went wrong while trying to submit the form. Please try again."
		}
	},
	Search: {
		search: "Type a term...",
		sort: {
			label: "Sorting",
			relevance: "Sort by relevance",
			date: "Sort by date"
		}
	},
	SocialShare: {
		button: "Share",
		label: "Share this page",
		facebook: "Facebook",
		X: "X",
		linkedin: "LinkedIn",
		email: "Email"
	},
	ImageGallery: {
		modal: {
			button: "Open in modal",
			aria: "Image gallery modal"
		},
		navigation: {
			aria: "Image gallery navigation",
			previous: "Previous",
			next: "Next"
		}
	},
	Timeline: {
		item: "slide",
		menu: "Timeline menu"
	},
	Operation: {
		readmore: "Read more about this operation"
	}
};
export default i18n;
