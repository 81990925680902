import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import Term, { countryPropTypes, termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import Weblinks from "common/Weblinks.js";
import RelatedContent from "common/RelatedContent.js";
import { toggleHeight } from "common/utils.js";

export const faqPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["faq"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	body: PropTypes.string.isRequired,
	summary: PropTypes.string,
	weblinks: weblinksPropTypes,
	countries: PropTypes.arrayOf(countryPropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	faqCategories: PropTypes.arrayOf(termPropTypes).isRequired,
	files: downloadsPropTypes,
	relatedContent: PropTypes.array // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
});

export const faqTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["faq"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	body: PropTypes.string.isRequired,
	faqCategories: PropTypes.arrayOf(termPropTypes).isRequired
});

export default class Faq extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: faqPropTypes
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<ClickableDrupalContent content={node.body} />
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class FaqTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: faqTeaserPropTypes
	};

	componentDidMount() {
		this.enhanceExpandableContent();
	}

	componentDidUpdate() {
		this.enhanceExpandableContent();
	}

	/**
	 * Mutates the rendered HTML content and enhances all expandables, adding
	 * appropriate classes and aria attributes.
	 */
	enhanceExpandableContent = () => {
		const button = this.domNode.querySelector("button");
		const content = this.domNode.querySelector(".expandable-content");
		if (!button || !content) return;
		const isExpanded = this.domNode.classList.contains("expanded");
		button.setAttribute("aria-expanded", isExpanded);
		button.addEventListener(
			"click",
			() => {
				toggleHeight(content, 300);
				const expanded = this.domNode.classList.toggle("expanded");
				button.setAttribute("aria-expanded", expanded);
			},
			false
		);
	};

	render() {
		const { t, node } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article ref={node => (this.domNode = node)} className={extraCssClass} key={node.id}>
				<div className="content-wrapper">
					<h3>
						<button>{node.title}</button>
					</h3>
					<div className="categories">
						<label className="show-for-sr">{t("Meta.label.categories")}</label>
						<ul>
							{node.faqCategories.map((term, index) => (
								<li key={index}>
									<Term term={term} />
								</li>
							))}
						</ul>
					</div>
					<div className="expandable-content">
						<ClickableDrupalContent content={node.body} />
					</div>
				</div>
			</article>
		);
	}
}
