import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Image, { imagePropTypes } from "common/Image.js";

export default class NodeImages extends PureComponent {
	static propTypes = {
		images: PropTypes.arrayOf(imagePropTypes),
		type: PropTypes.oneOf(["full", "portrait", "multimedia"]).isRequired
	};
	static defaultProps = {
		type: "full"
	};

	render() {
		const { images, type } = this.props;
		if (!images || !images.length) return null;
		return (
			<div className="NodeImages">
				<ul>
					{images.map((image, index) => (
						<li key={index}>
							<Image image={image} type={type} />
						</li>
					))}
				</ul>
			</div>
		);
	}
}
