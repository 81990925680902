import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { renderUrl } from "common/utils.js";
import { languagesPropTypes } from "common/Languages.js";
import { LANGUAGE_SWITCHER_TARGET_PAGE_ALIAS } from "config.js";

export default class LanguageSwitcher extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		languages: languagesPropTypes.isRequired
	};

	state = {
		isOpen: false
	};

	handleToggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	componentDidMount() {
		document.addEventListener("click", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick, false);
	}

	handleClick = e => {
		if (
			this.languageSwitcherToggleDomNode.classList.contains("open") &&
			!this.languageSwitcherToggleDomNode.contains(e.target)
		) {
			this.handleToggle();
		}
	};

	render() {
		const { t, languages } = this.props;
		return (
			<Fragment>
				<button
					className={this.state.isOpen ? "open" : ""}
					onClick={this.handleToggle}
					ref={node => (this.languageSwitcherToggleDomNode = node)}
				>
					{t("AppHeader.language")}
				</button>
				<nav role="navigation" className={`LanguageSwitcher ${this.state.isOpen ? "open" : ""}`}>
					<h2>{t("AppHeader.language")}</h2>
					<ul>
						{languages.map((lang, index) => (
							<li key={index}>
								<Link to={renderUrl(LANGUAGE_SWITCHER_TARGET_PAGE_ALIAS, lang.code)}>
									<span>{lang.code}</span>
									{lang.title}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</Fragment>
		);
	}
}
