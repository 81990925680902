import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import Image, { imagePropTypes } from "common/Image.js";

export default class ImageGallery extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		images: PropTypes.arrayOf(imagePropTypes),
		type: PropTypes.oneOf(["full", "portrait", "multimedia"]).isRequired
	};

	static defaultProps = {
		type: "full"
	};

	state = {
		activeSlide: 0,
		isModalOpen: false
	};

	setActiveSlide = index => {
		const total = this.props.images.length;
		let newIndex = index;
		if (newIndex >= total) newIndex = 0;
		if (newIndex < 0) newIndex = total - 1;
		this.setState({ activeSlide: newIndex, isModalOpen: true });
	};

	closeModal = () => {
		this.setState({ activeSlide: 0, isModalOpen: false });
	};

	handlePossibleEscapeKeyPress = e => {
		if (
			e.key === "Escape" && // https://stackoverflow.com/a/3369624/72478
			this.state.isModalOpen
		) {
			this.closeModal();
		}
	};

	componentDidMount() {
		document.addEventListener("keydown", this.handlePossibleEscapeKeyPress, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handlePossibleEscapeKeyPress, false);
	}

	render() {
		const { t, images, type } = this.props;
		if (!images || !images.length) return null;
		const showNavigation = images.length > 1;
		return (
			<Fragment>
				<div className="ImageGallery">
					<ul>
						{images.map((image, index) => (
							<li key={index}>
								<div>
									<button type="button" onClick={() => this.setActiveSlide(index)}>
										{t("ImageGallery.modal.button")}
									</button>
									<Image image={image} type={type} />
								</div>
							</li>
						))}
					</ul>
				</div>
				<div
					className={`modal ${this.state.isModalOpen ? "open" : ""}`}
					role="dialog"
					aria-label={t("ImageGallery.modal.aria")}
				>
					<button className="button close" type="button" onClick={this.closeModal}>
						{t("close")}
					</button>
					<ul>
						{images.map((image, index) => (
							<li key={index} className={index === this.state.activeSlide ? "active" : ""}>
								<Image image={image} type="modal" />
							</li>
						))}
					</ul>
					{showNavigation && (
						<nav role="navigation" aria-label={t("ImageGallery.navigation.aria")}>
							<button
								className="button previous"
								type="button"
								onClick={() => this.setActiveSlide(this.state.activeSlide - 1)}
							>
								{t("ImageGallery.navigation.previous")}
							</button>
							<button
								className="button next"
								type="button"
								onClick={() => this.setActiveSlide(this.state.activeSlide + 1)}
							>
								{t("ImageGallery.navigation.next")}
							</button>
						</nav>
					)}
				</div>
			</Fragment>
		);
	}
}
