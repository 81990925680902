import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class DocumentMeta extends PureComponent {
	static propTypes = {
		description: PropTypes.string
	};

	/**
	 * Removes any og: related metadata which may exist on the HTML which
	 * were generated by ssr.php.
	 */
	removeMeta() {
		const ogMetaElements = window.document.querySelectorAll('html > head > meta[property^="og:"]');
		Array.prototype.forEach.call(ogMetaElements, ogMetaElement =>
			ogMetaElement.parentNode.removeChild(ogMetaElement)
		);
		const canonical = window.document.querySelector('html > head > link[rel="canonical"]');
		if (canonical) {
			canonical.parentNode.removeChild(canonical);
		}
	}

	componentWillUnmount() {
		this.removeMeta();
		this.setDescription(undefined);
	}

	setDescription(value) {
		this.set('html > head > meta[name="description"]', "content", value);
	}

	set(selector, attribute, value) {
		const node = window.document.querySelector(selector);
		if (!node) return;
		node.setAttribute(attribute, value || "");
	}

	render() {
		const { description } = this.props;
		this.setDescription(description);
		return null;
	}
}
