import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export const statusPropTypes = PropTypes.oneOf(["loading", "error-404", "error-401", "error-5xx", "error-network"]);

export default class Loading extends PureComponent {
	static propTypes = {
		t: PropTypes.func,
		status: statusPropTypes,
		retry: PropTypes.func,
		close: PropTypes.func
	};

	static defaultProps = {
		status: "loading"
	};

	render() {
		const { t, status, retry } = this.props;

		return (
			<div className={"Loading" + (status ? " " + status : "")}>
				{status === "loading" && (
					<div>
						<p>{t ? t("Loading.loading") : "Loading..."}</p>
					</div>
				)}
				{status === "error-404" && (
					<Fragment>
						<h1>{t("Loading.error404.header")}</h1>
						<h2>{t("Loading.error404.subheader")}</h2>
						<p>{t("Loading.error404.content")}</p>
						<div>
							<Link className="button turquoise small more" to="/">
								{t("Loading.error404.homeButton")}
							</Link>
							<Link className="button turquoise small more" to="/search">
								{t("Loading.error404.searchButton")}
							</Link>
						</div>
					</Fragment>
				)}
				{status === "error-401" && (
					<Fragment>
						<h1>{t("Loading.error401.header")}</h1>
						<h2>{t("Loading.error401.subheader")}</h2>
						<p>{t("Loading.error401.content")}</p>
					</Fragment>
				)}
				{status === "error-5xx" && (
					<Fragment>
						<h1>{t("Loading.error5xx.header")}</h1>
						<p>{t("Loading.error5xx.content")}</p>
						{retry && (
							<button onClick={retry} className="button turquoise small retry">
								{t("retry")}
							</button>
						)}
					</Fragment>
				)}
				{status === "error-network" && (
					<Fragment>
						<h1>{t("Loading.errorNetwork.header")}</h1>
						<p>{t("Loading.errorNetwork.content")}</p>
						{retry && (
							<button onClick={retry} className="button turquoise small retry">
								{t("retry")}
							</button>
						)}
					</Fragment>
				)}
			</div>
		);
	}
}
