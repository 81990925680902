import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { countryPropTypes, termPropTypes } from "common/Term.js";
import { renderDate, renderDateIso } from "common/utils.js";
import Meta from "common/Meta.js";
import Weblinks from "common/Weblinks.js";
import ImageGallery from "common/ImageGallery.js";
import RelatedContent from "common/RelatedContent.js";
import Block from "common/Block.js";
import { Link } from "react-router-dom";

export const newsPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["news"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	introduction: PropTypes.string,
	body: PropTypes.string,
	isEmpact: PropTypes.bool,
	empactDescription: PropTypes.string,
	summary: PropTypes.string,
	mainImage: imagePropTypes,
	images: PropTypes.arrayOf(imagePropTypes),
	weblinks: weblinksPropTypes,
	countries: PropTypes.arrayOf(countryPropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	operations: PropTypes.arrayOf(termPropTypes),
	services: PropTypes.arrayOf(termPropTypes),
	organisations: PropTypes.arrayOf(termPropTypes),
	documentType: PropTypes.arrayOf(termPropTypes).isRequired,
	articleType: termPropTypes.isRequired,
	subtitle: PropTypes.string,
	files: downloadsPropTypes,
	navigation: PropTypes.exact({
		previous: PropTypes.string,
		next: PropTypes.string
	}),
	relatedContent: PropTypes.array, // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
	ogImage: PropTypes.string,
	ogDescription: PropTypes.string
});

export const newsTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["news"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	mainImage: imagePropTypes,
	articleType: termPropTypes // .isRequired on HomePage but not on Listings (yet)
});

export default class News extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: newsPropTypes,
		blocks: PropTypes.object
	};

	render() {
		const { t, node, blocks } = this.props;
		return (
			<Fragment>
				{node.introduction && <ClickableDrupalContent content={node.introduction} />}
				<ImageGallery t={t} images={node.images} />
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				{node.isEmpact && (
					<div className="Empact" id="empact">
						<h2 className="show-for-sr">{t("Empact.heading")}</h2>
						{node.empactDescription ? (
							<ClickableDrupalContent content={node.empactDescription} />
						) : (
							<Block name="empact" blocks={blocks} />
						)}
					</div>
				)}
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class NewsTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: newsTeaserPropTypes,
		relatedContentView: PropTypes.bool
	};

	static defaultProps = {
		relatedContentView: false // Set different image size in case that appears on related content list EPOLD8-291.
	};

	render() {
		const { t, node, relatedContentView } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && (
						<Image image={node.mainImage} type={relatedContentView ? "related_content" : "teaser"} />
					)}
					{!node.mainImage && <span className="no-image"></span>}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					<div className="publish-date">
						<label className="show-for-sr">{t("Meta.label.date")}</label>
						<time date={renderDateIso(node.published)}>{renderDate(node.published, t("locale.full"))}</time>
					</div>
					<Meta t={t} node={node} />
				</div>
			</article>
		);
	}
}
