import axios from "axios";
import removeFbclid from "remove-fbclid";
import MatomoTracker from "@datapunt/matomo-tracker-js";

export const BACKEND_PATH = "/cms/";
export const BACKEND_LOGIN_PATH = BACKEND_PATH + "user/login";

export const DEFAULT_LANGUAGE = "en";
export const DEFAULT_TIMEZONE = "Europe/Amsterdam";

export const UPLOAD_MIME_TYPES = ["image/png", "image/gif", "image/jpeg"];

// EPOLD8-312. Link specific keywords term "Empact" to specific page.
export const EMPACT_TERM_ID = 1;
export const EMPACT_TERM_TARGET_PAGE_ALIAS = "/keywords/empact";

export const LANGUAGE_SWITCHER_TARGET_PAGE_ALIAS = "/about-europol"; // The "About" page which is the fixed target of the language switcher EPOLD8-223.
export const COOKIES_PAGE_ALIAS = "/cookies"; // Page with cookie policy text EPOLD8-32.
export const EU_MEMBER_STATES_CODES =
	"AT|BE|BG|HR|CY|CZ|DK|EE|FI|FR|DE|GR|HU|IE|IT|LV|LT|LU|MT|NL|PL|PT|RO|SK|SI|ES|SE".split("|");

const MATOMO_URL = "https://www.europol.europa.eu/piwik"; // EPOLD8-51.
const MATOMO_SITE = 3; // EPOLD8-51.

export const MENU_IDS = ["main"];
export const BLOCK_TITLES = [
	"footer-bottom-first",
	"footer-bottom-second",
	"footer-bottom-third",
	"footer-top",
	"empact",
	"child-abuse-privacy"
];

export default function configureApp() {
	removeFbclid();
	configureAxios();
	registerServiceWorker();
	loadWebtools();
	showCookieConsentModal(() => {
		// Allow tracking if user makes any choice (accept all or accept essential).
		window.addEventListener("cck_all_accepted", () => {
			// User pressed "I accept all cookies". Setup tracker and track this page.
			startPiwikTracker();
			trackPageView();
		});
		window.addEventListener("cck_technical_accepted", () => {
			// User pressed "Accept only essential cookies". Setup tracker and track this page.
			startPiwikTracker();
			trackPageView();
		});
		// https://webgate.ec.europa.eu/fpfis/wikis/display/webtools/Cookie+Consent+Kit+v2
		if (window.$wt.cookie.consent.is.choiceMade()) {
			startPiwikTracker();
			trackPageView();
		}
	});
}

function configureAxios() {
	console.log("Configuring axios");
	axios.defaults.timeout = process.env.NODE_ENV === "production" ? 30000 : 15000;
	axios.defaults.baseURL = BACKEND_PATH;
}

function registerServiceWorker() {
	if (!("serviceWorker" in navigator)) {
		console.warn("Service workers are not supported by this browser");
		return;
	}
	console.log("Registering service worker");
	navigator.serviceWorker
		.register("/service-worker.js", {
			scope: "/"
		})
		.then(
			() => {
				console.log("Service worker has been registered");
			},
			e => {
				console.error("Service worker registration failed", e);
			}
		);
}

function loadWebtools() {
	const script = document.createElement("script");
	script.type = "text/javascript";
	script.src = "https://europa.eu/webtools/load.js";
	script.defer = "defer";
	window.document.head.appendChild(script);
}

function showCookieConsentModal(callback) {
	// html element is needed for webtools widget to be rendered.
	const cookieConsentBanner = document.createElement("div");
	cookieConsentBanner.id = "cookie-consent-banner";
	window.document.body.appendChild(cookieConsentBanner);
	window.addEventListener(
		"wtReady",
		function () {
			const $wt = window.$wt;
			$wt.render("cookie-consent-banner", {
				utility: "cck",
				url: COOKIES_PAGE_ALIAS
			});
			if (callback) callback();
		},
		false
	);
}

let tracker;
function startPiwikTracker() {
	// Only do piwik tracking on a production build.
	if (process.env.NODE_ENV !== "production") return;

	tracker = new MatomoTracker({
		urlBase: MATOMO_URL,
		srcUrl: MATOMO_URL + "/piwik.js", // Remove this line when matomo is upgraded from 3 to 4.
		trackerUrl: MATOMO_URL + "/piwik.php", // Remove this line when matomo is upgraded from 3 to 4.
		siteId: MATOMO_SITE
	});
}

export const trackPageView = () => tracker && tracker.trackPageView();
