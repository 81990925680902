import React from "react";
import ReactDOM from "react-dom";
import configureApp from "config.js";
import { RoutedApp } from "skeleton/App.js";
import "sass/global.scss";

console.log(`React: ${React.version}`);
configureApp();

ReactDOM.render(<RoutedApp />, window.document.getElementById("root"));
