import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { weblinksPropTypes } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { countryPropTypes, termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import TopMeta from "common/TopMeta.js";
import TenderMeta from "common/TenderMeta.js";
import Weblinks from "common/Weblinks.js";
import RelatedContent from "common/RelatedContent.js";
import { Link } from "react-router-dom";
import Term from "common/Term.js";

export const tenderPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["tender"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	body: PropTypes.string,
	summary: PropTypes.string,
	weblinks: weblinksPropTypes,
	countries: PropTypes.arrayOf(countryPropTypes),
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	subtitle: PropTypes.string,
	documentType: PropTypes.arrayOf(termPropTypes).isRequired,
	tenderStatus: termPropTypes.isRequired,
	tenderType: termPropTypes.isRequired,
	tenderValue: termPropTypes,
	tenderNumber: PropTypes.string.isRequired,
	tenderDeadlineDate: PropTypes.number.isRequired,
	tenderContractSignedDate: PropTypes.number,
	tenderAwarded: PropTypes.string,
	tenderContact: PropTypes.object,
	files: downloadsPropTypes,
	relatedContent: PropTypes.array // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
});

export const tenderTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["tender"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	tenderStatus: termPropTypes.isRequired,
	tenderType: termPropTypes.isRequired,
	tenderValue: termPropTypes,
	tenderNumber: PropTypes.string.isRequired,
	tenderDeadlineDate: PropTypes.number.isRequired
});

export default class Tender extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: tenderPropTypes
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<TopMeta t={t} node={node} />
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class TenderTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: tenderTeaserPropTypes
	};

	render() {
		const { t, node } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					<div className="status">
						<label className="show-for-sr">{t("Meta.label.tenderStatus")}</label>
						<span>
							<Term term={node.tenderStatus} />
						</span>
					</div>
					<TenderMeta t={t} node={node} />
				</div>
			</article>
		);
	}
}
