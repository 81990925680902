import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { StaffMemberTeaser } from "nodes/StaffMember.js";

export default class StaffMembersViewNodeList extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		list: PropTypes.object.isRequired
	};

	render() {
		const { t, list } = this.props;

		return (
			<Fragment>
				<Slider arrows={false} dots={true}>
					{list.items.map((node, index) => (
						<Fragment key={`s-${index}`}>
							<StaffMemberTeaser t={t} node={node} />
						</Fragment>
					))}
				</Slider>
			</Fragment>
		);
	}
}
