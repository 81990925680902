import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Image, { imagePropTypes } from "common/Image.js";

const sectionPropTypes = PropTypes.exact({
	title: PropTypes.string.isRequired,
	backgroundImage: imagePropTypes,
	description: PropTypes.string.isRequired
});

export const timelinePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["timeline"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	sections: PropTypes.arrayOf(sectionPropTypes).isRequired
});

export default class Timeline extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: timelinePropTypes
	};

	state = {
		activeMenu: false
	};

	componentDidMount() {
		this.toggleBodyClass();
	}

	componentWillUnmount() {
		this.toggleBodyClass();
	}

	/**
	 * TODO SOM: Alternative/better way to implement it ?.
	 * Add custom class to body to disable scroll via css.
	 */
	toggleBodyClass = () => {
		document.body.classList.toggle("timeline");
	};

	toggleMenu = () => {
		this.setState({ activeMenu: !this.state.activeMenu });
	};

	render() {
		const { t, node } = this.props;
		const renderSlide = (index, section) => (
			<Fragment>
				<div className="slide" id={"slide" + (index + 1)} key={index}>
					{index > 0 && (
						<a href={index - 1 === 0 ? "#root" : "#slide" + index} className="previous">
							{t("previous")} {t("Timeline.item")}
						</a>
					)}
					<div className="slide-content">
						<h2>{section.title}</h2>
						<ClickableDrupalContent content={section.description} />
					</div>
					{section.backgroundImage && <Image image={section.backgroundImage} type="full_screen" />}
					<a href={"#slide" + (index + 2)} className="next">
						{t("next")} {t("Timeline.item")}
					</a>
				</div>
			</Fragment>
		);
		return (
			<Fragment>
				<h1 className="show-for-sr">{node.title}</h1>
				<button className="toggleTimelineMenu button turquoise" type="button" onClick={this.toggleMenu}>
					{t("Timeline.menu")}
				</button>
				<div className={`timelineMenuModal ${this.state.activeMenu ? "open" : ""}`}>
					<h1>{node.title}</h1>
					<div>
						<ol>
							{node.sections.map((section, index) => (
								<li key={index}>
									<a href={index === 0 ? "#root" : "#slide" + (index + 1)} onClick={this.toggleMenu}>
										{section.title}
									</a>
								</li>
							))}
						</ol>
						<button className="close" type="button" onClick={this.toggleMenu}>
							{t("close")}
						</button>
					</div>
				</div>
				{node.sections.map((section, index) => (
					<Fragment key={index}>{renderSlide(index, section)}</Fragment>
				))}
			</Fragment>
		);
	}
}
