import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class SocialShare extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		url: PropTypes.string.isRequired
	};

	state = {
		isOpen: false
	};

	handleToggle = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	componentDidMount() {
		document.addEventListener("click", this.handleClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClick, false);
	}

	handleClick = e => {
		if (this.socialToggleDomNode.classList.contains("open") && !this.socialToggleDomNode.contains(e.target)) {
			this.handleToggle();
		}
	};

	render() {
		const { t, url } = this.props;
		const shareTitle = encodeURIComponent(window.document.title);
		const shareUrl = encodeURI(window.location.origin + url);
		return (
			<div className="SocialShare">
				<button
					onClick={this.handleToggle}
					className={`button turquoise ${this.state.isOpen ? "open" : ""}`}
					ref={node => (this.socialToggleDomNode = node)}
				>
					{t("SocialShare.button")}
				</button>
				<div className={this.state.isOpen ? "open" : ""}>
					<label>{t("SocialShare.label")}</label>
					<ul>
						<li className="facebook">
							<a
								href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
								rel="external noopener noreferrer nofollow"
							>
								{t("SocialShare.facebook")}
							</a>
						</li>
						<li className="linkedIn">
							<a
								rel="external noopener noreferrer nofollow"
								href={`https://www.linkedin.com/shareArticle/?mini=true&url=${shareUrl}&title=${shareTitle}`}
							>
								{t("SocialShare.linkedin")}
							</a>
						</li>
						<li className="X">
							<a
								rel="external noopener noreferrer nofollow"
								href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`}
							>
								{t("SocialShare.X")}
							</a>
						</li>
						<li className="email">
							<a
								href={`mailto:?subject=${shareTitle}&body=${shareUrl}`}
								rel="external noopener noreferrer"
							>
								{t("SocialShare.email")}
							</a>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}
