import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import { Link } from "react-router-dom";
import ImageGallery from "common/ImageGallery.js";
import Form, { formPropTypes } from "nodes/Form.js";
import Block from "common/Block.js";

export const childAbusePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["child_abuse"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	images: PropTypes.arrayOf(imagePropTypes).isRequired,
	summary: PropTypes.string.isRequired,
	body: PropTypes.string,
	form: formPropTypes,
	blocks: PropTypes.object
});

export const childAbuseTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["child_abuse"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	mainImage: imagePropTypes.isRequired,
	summary: PropTypes.string.isRequired
});

export default class ChildAbuse extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: childAbusePropTypes
	};

	render() {
		const { t, node, blocks } = this.props;
		return (
			<Fragment>
				<div className={`image-wrapper ${node.form ? "has-form" : ""}`}>
					<ImageGallery t={t} images={node.images} />
				</div>
				<ClickableDrupalContent content={node.body} />
				{node.form && <Form t={t} form={node.form} url={node.alias} />}
				<Block name="child-abuse-privacy" blocks={blocks} />
			</Fragment>
		);
	}
}

export class ChildAbuseTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: childAbuseTeaserPropTypes
	};

	render() {
		const { node } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		return (
			<article className={extraCssClass} key={node.id}>
				<Link to={node.alias}>
					<div className="image-wrapper">
						<Image image={node.mainImage} type="teaser" />
					</div>
					<div className="content-wrapper">
						<h3>{node.summary}</h3>
					</div>
				</Link>
			</article>
		);
	}
}
