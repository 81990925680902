import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { getHumanReadableFileSize } from "common/utils.js";

export const downloadsPropTypes = PropTypes.objectOf(
	PropTypes.arrayOf(
		PropTypes.exact({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			size: PropTypes.number.isRequired,
			type: PropTypes.string.isRequired,
			language: PropTypes.string.isRequired,
			languageCode: PropTypes.string.isRequired
		})
	)
);

export default class Downloads extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		downloads: downloadsPropTypes
	};

	render() {
		const { t, downloads } = this.props;
		if (!downloads) return null;

		const renderFiles = group => {
			let file = group.slice(0).shift();
			let availableTranslations = group.slice(1, group.length);
			return (
				<Fragment>
					<div className="file">
						<a href={file.url}>{file.title}</a>
						<span className={`type ${file.type}`}>{file.type}</span>
						<span className="language">{file.languageCode}</span>
						<span className="size">({getHumanReadableFileSize(file.size)})</span>
					</div>
					{availableTranslations.length > 0 && (
						<div className="otherLanguages">
							<label>{t("Downloads.otherLanguagesLabel")}:</label>
							<ul>
								{availableTranslations.map((file, index) => (
									<li key={index}>
										<a href={file.url}>{file.languageCode}</a>
									</li>
								))}
							</ul>
						</div>
					)}
				</Fragment>
			);
		};

		return (
			<div className="Downloads layout curve dark-blue" id="downloads">
				<div>
					<h2>{t("Downloads.label")}</h2>
					<ul>
						{Object.keys(downloads).map(function (keyName, keyIndex) {
							return <li key={keyName}>{renderFiles(downloads[keyName])}</li>;
						})}
					</ul>
				</div>
			</div>
		);
	}
}
