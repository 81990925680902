import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import Weblinks, { weblinksPropTypes, renderLink } from "common/Weblinks.js";
import Downloads, { downloadsPropTypes } from "common/Downloads.js";
import { organisationPropTypes, countryPropTypes, termPropTypes } from "common/Term.js";
import Meta from "common/Meta.js";
import RelatedContent from "common/RelatedContent.js";
import Image, { imagePropTypes } from "common/Image.js";
import { Link } from "react-router-dom";
import { renderDate, renderDateIso } from "common/utils.js";
import Wrapper from "common/Wrapper.js";

export const agreementPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["agreement"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	subtitle: PropTypes.string,
	body: PropTypes.string,
	summary: PropTypes.string,
	weblinks: weblinksPropTypes,
	documentType: PropTypes.arrayOf(termPropTypes).isRequired,
	agreementType: termPropTypes.isRequired,
	documentForceDate: PropTypes.number,
	documentSignatureDate: PropTypes.number,
	documentRatificationDate: PropTypes.number,
	organisation: organisationPropTypes,
	country: countryPropTypes,
	crimeAreas: PropTypes.arrayOf(termPropTypes),
	keywords: PropTypes.arrayOf(termPropTypes),
	entities: PropTypes.arrayOf(termPropTypes),
	files: downloadsPropTypes,
	relatedContent: PropTypes.array // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
});

export const agreementTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["agreement"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	promoted: PropTypes.bool,
	mainImage: imagePropTypes,
	country: countryPropTypes
});

export default class Agreement extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: agreementPropTypes
	};

	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<Wrapper className="agreementMeta layout grey">
					<Wrapper>
						{node.organisation && (
							<Fragment>
								<Image image={node.organisation.mainImage} type="full" />
								<h2>{node.organisation.title}</h2>
								{node.organisation.website && (
									<ul className="websites">
										{node.organisation.website.map((link, index) => (
											<li key={index}>{renderLink(link)}</li>
										))}
									</ul>
								)}
							</Fragment>
						)}
						{node.country && <h2 className={`country_${node.country.code}`}>{node.country.name}</h2>}
						<Wrapper className="dates" htmlTag="ul">
							{node.documentRatificationDate && (
								<li>
									<label>{`${t("Meta.label.documentRatificationDate")}: `}</label>
									<time date={renderDateIso(node.documentRatificationDate)}>
										{renderDate(node.documentRatificationDate, t("locale.full"))}
									</time>
								</li>
							)}
							{node.documentSignatureDate && (
								<li>
									<label>{`${t("Meta.label.documentSignatureDate")}: `}</label>
									<time date={renderDateIso(node.documentSignatureDate)}>
										{renderDate(node.documentSignatureDate, t("locale.full"))}
									</time>
								</li>
							)}
							{node.documentForceDate && (
								<li>
									<label>{`${t("Meta.label.documentForceDate")}: `}</label>
									<time date={renderDateIso(node.documentForceDate)}>
										{renderDate(node.documentForceDate, t("locale.full"))}
									</time>
								</li>
							)}
						</Wrapper>
					</Wrapper>
				</Wrapper>
				{node.body && <ClickableDrupalContent content={node.body} />}
				<Downloads t={t} downloads={node.files} />
				<Meta t={t} node={node} />
				<Weblinks t={t} weblinks={node.weblinks} />
				<RelatedContent t={t} relatedContent={node.relatedContent} />
			</Fragment>
		);
	}
}

export class AgreementTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: agreementTeaserPropTypes,
		relatedContentView: PropTypes.bool
	};

	static defaultProps = {
		relatedContentView: false // Set different image size in case that appears on related content list EPOLD8-291.
	};

	render() {
		const { t, node, relatedContentView } = this.props;
		let extraCssClass = "NodeTeaser";
		extraCssClass += ` ct_${node.type}`;
		if (node.promoted) extraCssClass += ` promoted`;
		return (
			<article className={extraCssClass} key={node.id}>
				<div className="image-wrapper">
					{node.mainImage && (
						<Image image={node.mainImage} type={relatedContentView ? "related_content" : "teaser"} />
					)}
					{!node.mainImage && (
						<span className={`${node.country ? `country_${node.country.code}` : ""} no-image`}></span>
					)}
				</div>
				<div className="content-wrapper">
					<h3>
						<Link to={node.alias}>{node.title}</Link>
					</h3>
					<div className="publish-date">
						<label className="show-for-sr">{t("Meta.label.date")}</label>
						<time date={renderDateIso(node.published)}>{renderDate(node.published, t("locale.full"))}</time>
					</div>
					<Meta t={t} node={node} />
				</div>
			</article>
		);
	}
}
