import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isCmsLink } from "common/utils.js";

export const imagePropTypes = PropTypes.exact({
	alt: PropTypes.string.isRequired,
	mimeType: PropTypes.string.isRequired,
	thumbs: PropTypes.arrayOf(
		PropTypes.exact({
			url: PropTypes.string.isRequired,
			width: PropTypes.number.isRequired,
			height: PropTypes.number.isRequired
		})
	).isRequired
});

export const getImageDerivative = (url, extension) => {
	const indexOfQuestionmark = url.lastIndexOf("?");
	return indexOfQuestionmark === -1
		? url + "." + extension
		: url.substr(0, indexOfQuestionmark) + "." + extension + url.substr(indexOfQuestionmark);
};

/**
 * Implements responsive images ERA-73.
 */
export default class Image extends PureComponent {
	static propTypes = {
		image: imagePropTypes,
		type: PropTypes.oneOf([
			"full",
			"teaser",
			"banner",
			"portrait_teaser",
			"portrait",
			"modal",
			"multimedia",
			"related_content",
			"avatar",
			"full_screen"
		]).isRequired
	};

	render() {
		const { image, type } = this.props;
		if (!image) return null;
		const sizes =
			type === "full"
				? "(max-width: 1024px) 100vw, 1024px"
				: type === "teaser"
				? "(max-width: 420px) 100vw, (max-width: 800px) 50vw, (max-width: 1280px) 33.3vw, (max-width: 1920px) 25vw, 430px"
				: type === "banner"
				? "(max-width: 1024px) 100vw, 885px"
				: type === "portrait_teaser"
				? "(max-width: 420px) 100vw, (max-width: 640px) 50vw, (max-width: 1024px) 33.3vw, (max-width: 1440px) 25vw, (max-width: 1920px) 20vw, 340px"
				: type === "portrait"
				? "250px"
				: type === "modal"
				? "(max-width: 1920px) 100vw, 1810px"
				: type === "multimedia"
				? "(max-width: 420px) 100vw, (max-width: 640px) 50vw, (max-width: 1024px) 33.3vw, 335px"
				: type === "related_content"
				? "(max-width: 420px) 100vw, (max-width: 768px) 50vw, (max-width: 1200px) 33.3vw, 310px"
				: type === "full_screen"
				? "(max-width: 1024px) 150vw, 2000px"
				: undefined;
		const middleThumb = image.thumbs[Math.floor(image.thumbs.length / 2)];
		// False in storybook where we inject absolute jpg URLs from placekitten.com
		const shouldRenderWebp = isCmsLink(middleThumb.url);
		return (
			<picture className="Image">
				{shouldRenderWebp ? (
					<source
						type="image/webp"
						srcSet={image.thumbs.map(
							(thumb, index, { length }) =>
								`${getImageDerivative(thumb.url, "webp")} ${thumb.width}w${
									length - 1 === index ? "," : ""
								}`
						)}
						sizes={sizes}
					/>
				) : (
					""
				)}
				<source
					type={image.mimeType}
					srcSet={image.thumbs.map(
						(thumb, index, { length }) => `${thumb.url} ${thumb.width}w${length - 1 === index ? "," : ""}`
					)}
					sizes={sizes}
				/>

				<img
					src={middleThumb.url}
					alt={image.alt}
					width={middleThumb.width}
					height={middleThumb.height}
					loading="lazy"
					decoding="async"
				/>
			</picture>
		);
	}
}
