import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import DateRange from "common/DateRange.js";
import Term from "common/Term.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import { renderLink } from "common/Weblinks.js";

export default class TopMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired // Should have used nodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
	};

	render() {
		const { t, node } = this.props;
		return (
			<div className="TopMeta box blue curve">
				<h2>{t("NodeType." + node.type)}</h2>
				<ul className="layout">
					{node.type === "event" && (
						<Fragment>
							<li className="event-date">
								<h3>{t("EventTopMeta.dateLabel")}</h3>
								<DateRange t={t} startDate={node.startDate} endDate={node.endDate} />
							</li>
							{node.eventVenue && (
								<li className="event-location">
									<h3>{t("EventTopMeta.locationLabel")}</h3>
									{node.eventVenue && <span>{node.eventVenue}</span>}
								</li>
							)}
							{node.eventVenueLink && (
								<li className="event-venue">
									<h3>{t("EventTopMeta.eventVenueLink")}</h3>
									{renderLink(node.eventVenueLink)}
								</li>
							)}
							{node.eventRegistrationLink && (
								<li className="event-registration">
									<h3>{t("EventTopMeta.eventRegistrationLink")}</h3>
									{renderLink(node.eventRegistrationLink)}
								</li>
							)}
						</Fragment>
					)}
					{node.type === "tender" && (
						<Fragment>
							<li className="tender-type">
								<h3>{t("Meta.label.tenderType")}</h3>
								<span>
									<Term term={node.tenderType} />
								</span>
							</li>
							<li className="tender-status">
								<h3>{t("Meta.label.tenderStatus")}</h3>
								<span>
									<Term term={node.tenderStatus} />
								</span>
							</li>
							<li className="tender-number">
								<h3>{t("Meta.label.tenderNumber")}</h3>
								<span>{node.tenderNumber}</span>
							</li>
							{node.tenderAwarded && (
								<li className="tender-awarded">
									<h3>{t("Meta.label.tenderAwarded")}</h3>
									<ClickableDrupalContent content={node.tenderAwarded} />
								</li>
							)}
						</Fragment>
					)}
				</ul>
			</div>
		);
	}
}
