import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { loadNextItemsFromServer, handleError } from "skeleton/DataAccess.js";
import ClickableDrupalContent from "common/ClickableDrupalContent.js";
import history from "skeleton/history.js";
import { escapeHtml, serializeForm, unserializeForm, renderDate, renderDateIso } from "common/utils.js";
import { Link } from "react-router-dom";
import Image, { imagePropTypes } from "common/Image.js";

const searchResultsPropTypes = PropTypes.exact({
	itemType: PropTypes.oneOf(["search"]).isRequired,
	ids: PropTypes.arrayOf(PropTypes.number).isRequired,
	page: PropTypes.number.isRequired,
	itemsPerPage: PropTypes.number.isRequired,
	totalItems: PropTypes.number.isRequired,
	totalItemsLimit: PropTypes.number.isRequired,
	words: PropTypes.arrayOf(PropTypes.string).isRequired,
	sortOptions: PropTypes.arrayOf(PropTypes.oneOf(["relevance", "date"])).isRequired,
	items: PropTypes.arrayOf(
		PropTypes.exact({
			id: PropTypes.number.isRequired,
			type: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			language: PropTypes.string,
			languages: PropTypes.arrayOf(PropTypes.string),
			published: PropTypes.number.isRequired,
			mainImage: imagePropTypes,
			excerpt: PropTypes.string
		})
	).isRequired
});

export const searchPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["search"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	published: PropTypes.number.isRequired,
	updated: PropTypes.number.isRequired,
	summary: PropTypes.string,
	body: PropTypes.string,
	searchResults: searchResultsPropTypes.isRequired
});

export default class Search extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: searchPropTypes.isRequired,
		search: PropTypes.string
	};

	onSubmit = e => {
		e.preventDefault();
		history.push("?" + serializeForm(this.formDomNode));
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.node.searchResults !== this.props.node.searchResults) {
			this.setState({ searchResults: this.props.node.searchResults });
		}
	}

	enhanceDomForm = () => {
		if (!this.formDomNode) return;
		// Populate form with content from URL.
		unserializeForm(this.formDomNode, this.props.search);
	};

	componentDidMount() {
		this.enhanceDomForm();
	}

	state = {
		searchResults: this.props.node.searchResults
	};

	hasMore = () =>
		!this.state.searchResults.ids
			? false
			: this.state.searchResults.page * this.state.searchResults.itemsPerPage +
					this.state.searchResults.itemsPerPage <
			  this.state.searchResults.ids.length;

	loadMore = () => {
		const { t } = this.props;
		const { searchResults } = this.state;
		this.setState({ searchResults: { ...searchResults, loadingStatus: "loading" } });
		loadNextItemsFromServer(searchResults)
			.then(searchResults => this.setState({ searchResults: { ...searchResults, loadingStatus: undefined } }))
			.catch(error =>
				handleError(
					t,
					error,
					undefined, // 401
					undefined, // 404
					() => this.setState({ searchResults: { ...searchResults, loadingStatus: "error-5xx" } }),
					() => this.setState({ searchResults: { ...searchResults, loadingStatus: "error-network" } })
				)
			);
	};

	render() {
		const { t, node } = this.props;
		const { searchResults } = this.state;

		const renderForm = () => (
			<form method="get" className="filters" onSubmit={this.onSubmit} ref={node => (this.formDomNode = node)}>
				<div className="fields">
					<div className="field-type-text search">
						<label htmlFor="field-name-q" className="show-for-sr">
							{t("Search.search")}
						</label>
						<input id="field-name-q" type="text" maxLength="64" name="q" placeholder={t("Search.search")} />
					</div>
					<div className="field-type-select sort">
						<label className="show-for-sr" htmlFor="field-name-sort">
							{t("Search.sort.label")}
						</label>
						<select id="field-name-sort" name="sort">
							{searchResults.sortOptions.map((option, index) => (
								<option key={index} value={option}>
									{t("Search.sort." + option)}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="actions">
					<button className="button small" type="submit">
						{t("apply")}
					</button>
					<input className="button small hollow" type="reset" value={t("reset")} />
				</div>
			</form>
		);
		const renderNodes = () =>
			searchResults.items.length > 0 && (
				<Fragment>
					<ol className="layout">
						{searchResults.items.map((result, index) => {
							// Highlighting possible word matches on title.
							// Rendering them in a span since <NavLink /> cannot
							// accept dangerouslySetInnerHTML.
							const titleWithHighlightedWords = (
								<span
									dangerouslySetInnerHTML={{
										__html: escapeHtml(result.title).replace(
											new RegExp(`\\b(${searchResults.words.join("|")})\\b`, "gi"),
											"<strong>$1</strong>"
										)
									}}
								/>
							);
							return (
								<li key={index}>
									<article className={`searchTeaser ct_${result.type}`} key={result.id}>
										<div className="image-wrapper">
											{result.mainImage && <Image image={result.mainImage} type="teaser" />}
											{!result.mainImage && <span className="no-image"></span>}
										</div>
										<div className="content-wrapper">
											<h2>
												<Link to={result.alias}>{titleWithHighlightedWords}</Link>
											</h2>
											<div className="publish-date">
												<label className="show-for-sr">{t("Meta.label.date")}</label>
												<time date={renderDateIso(result.published)}>
													{renderDate(result.published, t("locale.full"))}
												</time>
											</div>
											{result.excerpt && <ClickableDrupalContent content={result.excerpt} />}
											<span className="type">{t("NodeType." + result.type)}</span>
										</div>
									</article>
								</li>
							);
						})}
					</ol>
				</Fragment>
			);
		return (
			<Fragment>
				{node.body && <ClickableDrupalContent content={node.body} />}
				<div className="searchList layout curve">
					<div>
						{renderForm()}
						{searchResults.totalItems > 0 && (
							<p className="results">
								{t("results")}{" "}
								<span>
									{t(
										searchResults.totalItems === searchResults.totalItemsLimit
											? "items_more"
											: "items",
										{
											items: searchResults.totalItems
										}
									)}
								</span>
							</p>
						)}
						{searchResults.totalItems ? (
							<Fragment>{renderNodes()}</Fragment>
						) : (
							<div className="no-results">
								<p>{t("no_results")}</p>
							</div>
						)}
						<button
							className="button"
							onClick={this.loadMore}
							disabled={searchResults.loadingStatus === "loading"}
							style={this.hasMore() ? undefined : { display: "none" }}
							ref={node => (this.moreButtonDomNode = node)}
						>
							{t(searchResults.loadingStatus === "loading" ? "Loading.loading" : "more")}
						</button>
					</div>
				</div>
			</Fragment>
		);
	}
}
