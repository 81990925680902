import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { VacancyTeaser } from "nodes/Vacancy.js";

export default class VacanciesViewNodeList extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		list: PropTypes.object.isRequired
	};

	render() {
		const { t, list } = this.props;

		const categories = [];

		const vacanciesPerCategory = list.items.reduce((map, node) => {
			if (!map[node.contractTypeGroup]) map[node.contractTypeGroup] = [];
			map[node.contractTypeGroup].push(node);

			if (!categories.find(contractTypeGroup => contractTypeGroup === node.contractTypeGroup)) {
				categories.push(node.contractTypeGroup);
			}
			return map;
		}, {});

		return (
			<Fragment>
				{categories
					.sort((a, b) => a.weight - b.weight)
					.map((category, index) => (
						<Fragment key={`d-${index}`}>
							<h3>{category}</h3>
							<ul className="layout">
								{vacanciesPerCategory[category].map((node, index) => (
									<li key={`s-${index}`}>
										<VacancyTeaser t={t} node={node} />
									</li>
								))}
							</ul>
						</Fragment>
					))}
			</Fragment>
		);
	}
}
