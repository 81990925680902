import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { AgreementTeaser } from "nodes/Agreement.js";
import { CountryTeaser } from "nodes/Country.js";
import { DocumentTeaser } from "nodes/Document.js";
import { EventTeaser } from "nodes/Event.js";
import { GuideTeaser } from "nodes/Guide.js";
import { MultimediaTeaser } from "nodes/Multimedia.js";
import { NewsTeaser } from "nodes/News.js";
import { OperationTeaser } from "nodes/Operation.js";
import { PageTeaser } from "nodes/Page.js";

export default class RelatedContent extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		section: PropTypes.string,
		relatedContent: PropTypes.array, // Should have used teaserNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: ReferenceError: Cannot access 'pagePropTypes' before initialization
		maxSlidesToShow: PropTypes.number
	};

	static defaultProps = {
		maxSlidesToShow: 3,
		section: "default"
	};

	getSlidesToShow = size => {
		if (size === "large") return 3;
		if (size === "med") return 2;
		if (size === "small") return 1;
		return 1;
	};

	render() {
		const { t, relatedContent, maxSlidesToShow, section } = this.props;
		if (!relatedContent || !relatedContent.length) return null;

		const sliderSettings = {
			arrows: true,
			dots: true,
			// to prevent https://github.com/kenwheeler/slick/issues/940
			// also: https://github.com/akiran/react-slick/issues/1171
			infinite: relatedContent.length > maxSlidesToShow,
			speed: 500,
			slidesToShow: maxSlidesToShow,
			slidesToScroll: maxSlidesToShow,
			responsive: [
				{
					breakpoint: 1366,
					settings: {
						slidesToShow: this.getSlidesToShow("large"),
						slidesToScroll: this.getSlidesToShow("large")
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: this.getSlidesToShow("med"),
						slidesToScroll: this.getSlidesToShow("med")
					}
				},
				{
					breakpoint: 420,
					settings: {
						slidesToShow: this.getSlidesToShow("small"),
						slidesToScroll: this.getSlidesToShow("small")
					}
				}
			]
		};

		return (
			<div className={`RelatedContent layout curve ${section}`}>
				<div>
					<h2>{t("RelatedContent.heading." + section)}</h2>
					<Slider {...sliderSettings}>
						{relatedContent.map((node, index) => (
							<Fragment key={index}>
								{node.type === "agreement" && (
									<AgreementTeaser t={t} node={node} relatedContentView={true} />
								)}
								{node.type === "country" && (
									<CountryTeaser t={t} node={node} relatedContentView={true} />
								)}
								{node.type === "document" && (
									<DocumentTeaser t={t} node={node} relatedContentView={true} />
								)}
								{node.type === "event" && <EventTeaser t={t} node={node} relatedContentView={true} />}
								{node.type === "guide" && <GuideTeaser t={t} node={node} relatedContentView={true} />}
								{node.type === "multimedia" && (
									<MultimediaTeaser t={t} node={node} relatedContentView={true} />
								)}
								{node.type === "news" && <NewsTeaser t={t} node={node} relatedContentView={true} />}
								{node.type === "operation" && (
									<OperationTeaser t={t} node={node} relatedContentView={true} />
								)}
								{node.type === "page" && <PageTeaser t={t} node={node} relatedContentView={true} />}
							</Fragment>
						))}
					</Slider>
				</div>
			</div>
		);
	}
}
