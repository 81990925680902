import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Term from "common/Term.js";
import { renderDate, renderDateIso } from "common/utils.js";

export default class TenderMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired // Should have used nodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
	};

	render() {
		const { t, node } = this.props;
		if (
			!node.tenderType &&
			!node.tenderValue &&
			!node.tenderNumber &&
			!node.tenderDeadlineDate &&
			!node.published &&
			!node.reference &&
			!node.published &&
			!node.deadline
		)
			return null;
		return (
			<ul className="meta">
				{node.tenderType && (
					<li>
						<label>{t("Meta.label.tenderType")}</label>
						<span>
							<Term term={node.tenderType} />
						</span>
					</li>
				)}
				{node.tenderValue && (
					<li>
						<label>{t("Meta.label.tenderValue")}</label>
						<span>
							<Term term={node.tenderValue} />
						</span>
					</li>
				)}
				{node.tenderNumber && (
					<li>
						<label>{t("Meta.label.tenderNumber")}</label>
						<span>{node.tenderNumber}</span>
					</li>
				)}
				{node.tenderDeadlineDate && (
					<li>
						<label>{t("Meta.label.tenderDeadlineDate")}</label>
						<time date={renderDateIso(node.tenderDeadlineDate)}>
							{renderDate(node.tenderDeadlineDate, t("locale.full"))}
						</time>
					</li>
				)}
				{node.published && (
					<li>
						<label>{t("Meta.label.date")}</label>
						<time date={renderDateIso(node.published)}>{renderDate(node.published, t("locale.full"))}</time>
					</li>
				)}
				{node.reference && (
					<li>
						<label>{t("Meta.label.tenderNumber")}</label>
						<span className="reference">{node.reference}</span>
					</li>
				)}
				{node.published && (
					<li>
						<label>{t("Meta.label.published")}</label>
						<span className="published">
							<time date={renderDateIso(node.published)}>
								{renderDate(node.published, t("locale.full"))}
							</time>
						</span>
					</li>
				)}
				{node.deadline && (
					<li>
						<label>{t("Meta.label.tenderDeadlineDate")}</label>
						<span className="deadline">
							<time date={renderDateIso(node.deadline)}>
								{renderDate(node.deadline, t("locale.full"))}
							</time>
						</span>
					</li>
				)}
			</ul>
		);
	}
}
