import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import Image from "common/Image.js";
import Wrapper from "common/Wrapper.js";
import { renderDate, renderDateIso } from "common/utils.js";
import { DEFAULT_TIMEZONE } from "config.js";
import DateRange from "common/DateRange.js";

const shouldRenderUpdateDate = node => node.type !== "news" && !node.lists && node.updated;

export default class NodeHeaderMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired
	};
	render() {
		const { t, node } = this.props;
		return (
			<Fragment>
				<Wrapper className="subtitle">
					{node.subtitle && <p>{node.subtitle}</p>}
					{(node.type === "vacancy" || node.type === "internship") && (
						<ul>
							{node.referenceNumber && (
								<li>
									<label className="show-for-sr">{t("Meta.label.referenceNumber")}</label>
									<span>{node.referenceNumber}</span>
								</li>
							)}
							{node.deadline && (
								<li>
									<label>{t("Meta.label.deadline")}:</label>
									<time date={renderDateIso(node.deadline, -24)}>
										{renderDate(node.deadline, t("locale.full"), {
											day: "2-digit",
											month: "short",
											year: "numeric",
											hour: "numeric",
											minute: "numeric",
											timeZoneName: "short",
											timeZone: DEFAULT_TIMEZONE
										})}
									</time>
								</li>
							)}
							{node.department && (
								<li>
									<label>{t("Meta.label.department")}:</label>
									<span>{node.department}</span>
								</li>
							)}
						</ul>
					)}
				</Wrapper>
				<div className="title-top">
					{node.type === "vacancy" || node.type === "internship" ? (
						<ul className={`decorative-heading ${node.restricted ? "restricted" : ""}`}>
							<li>
								<label className="show-for-sr">{t("Meta.label.postType")}</label>
								<span>{node.postType}</span>
							</li>
							{node.type === "vacancy" && (
								<li>
									<label>{t("Meta.label.contractType")}:</label>
									<span>{node.contractType}</span>
								</li>
							)}
						</ul>
					) : (
						<Fragment>
							<label className="show-for-sr">{t("NodeType.label")}</label>
							<span className="decorative-heading">
								{node.agreementType ? node.agreementType.title : node.type}
							</span>
						</Fragment>
					)}
				</div>
				<Wrapper className="dates" htmlTag="ul">
					{node.type === "operation" && (
						<li>
							<label>{t("Meta.label.dateRange")}:</label>
							<DateRange t={t} startDate={node.startDate} endDate={node.endDate} />
						</li>
					)}
					{node.published && node.type !== "page" && (
						<li className="publish-date">
							<label className="show-for-sr">{t("Meta.label.published")}</label>
							<time date={renderDateIso(node.published)}>
								{renderDate(node.published, t("locale.full"))}
							</time>
						</li>
					)}
					{shouldRenderUpdateDate(node) && (
						<li>
							<label>{t("Meta.label.updated")}:</label>
							<time date={renderDateIso(node.updated)}>{renderDate(node.updated, t("locale.full"))}</time>
						</li>
					)}
				</Wrapper>
				{node.type === "crime_areas" && node.backgroundImages && (
					<div className="graphics" aria-hidden="true">
						{node.backgroundImages.map((image, index) => (
							<Fragment key={index}>
								<Image image={image} type="full" />
							</Fragment>
						))}
					</div>
				)}
				{node.forthcoming && <div className="forthcoming-label">{t("forthcoming")}</div>}
			</Fragment>
		);
	}
}
