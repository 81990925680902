import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "common/Term.js";

export default class EventsSearchFormFields extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		types: PropTypes.arrayOf(termPropTypes).isRequired,
		years: PropTypes.arrayOf(PropTypes.number).isRequired,
		search: PropTypes.string,
		selectedFiltersCount: PropTypes.number
	};

	state = {
		activeFilters: false
	};

	toggleFilters = () => {
		this.setState({ activeFilters: !this.state.activeFilters });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.search !== this.props.search) {
			this.setState({ activeFilters: false });
		}
	}

	render() {
		const { t, types, years, selectedFiltersCount } = this.props;
		return (
			<Fragment>
				<div className="field-type-text search">
					<label htmlFor="field-name-q" className="show-for-sr">
						{t("NodeListSearchForm.search")}
					</label>
					<input
						id="field-name-q"
						type="text"
						maxLength="64"
						name="q"
						placeholder={t("NodeListSearchForm.search")}
					/>
				</div>
				<button className="button white toggleFilters" onClick={this.toggleFilters}>
					{t("NodeListSearchForm.filters.open")}
					{!!selectedFiltersCount && (
						<span className="counter">
							{selectedFiltersCount}
							<span className="show-for-sr">{t("NodeListSearchForm.filters.counter")}</span>
						</span>
					)}
				</button>
				<div className={`filtersModal ${this.state.activeFilters ? "open" : ""}`}>
					<h1>{t("NodeListSearchForm.filters.heading")}</h1>
					<div>
						<fieldset>
							<legend>{t("NodeListSearchForm.filters.type")}</legend>
							<ul className="layout">
								{types.map((term, index) => (
									<li className="field-type-checkbox" key={index}>
										<input type="checkbox" name="type" id={`type-${term.id}`} value={term.id} />
										<label htmlFor={`type-${term.id}`}>{term.title}</label>
									</li>
								))}
							</ul>
						</fieldset>
						<fieldset>
							<legend>{t("NodeListSearchForm.filters.year")}</legend>
							<ul className="layout">
								{years.map((value, index) => (
									<li className="field-type-radio" key={index}>
										<input type="radio" name="year" id={`year-${value}`} value={value} />
										<label htmlFor={`year-${value}`}>{value}</label>
									</li>
								))}
							</ul>
						</fieldset>
						<div className="actions">
							<input className="button turquoise" type="reset" value={t("reset")} />
							<button className="button turquoise" type="submit">
								{t("apply")}
							</button>
						</div>
						<button className="close" type="button" onClick={this.toggleFilters}>
							{t("close")}
						</button>
					</div>
				</div>
			</Fragment>
		);
	}
}
